
import moment from 'moment'


export const  formatDate= (date)=>{
    return moment(date).format("MMM Do hh:mm a")
}

export  const capitalize = (str)=> {
  if(str.split(" ").length > 1){
    const strs = str.split(" ");
    let res = "";
    for(let idx=0;idx < strs.length ;idx ++){
      res += singleCapitalize(strs[idx])
      res += " "
    }
    return res
  }
  return singleCapitalize(str)
}

const singleCapitalize = (str) => {
  return  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const elapsedTime = (date) => {
  const startDate = moment(date);
  const elapsedDuration = moment.duration(moment().diff(startDate));
  //in minutes
  const minutesElapsed = Math.ceil(elapsedDuration.asMinutes());
  if(minutesElapsed > 60){
    return Math.ceil(elapsedDuration.asHours()) + " hours ago"
  }
  return minutesElapsed + "minutes ago";
}
