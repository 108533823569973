<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item"
      >
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }"
        >
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="The Jump" :to="{ name: 'Jump' }">
          <template #icon>
            <magazine />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Circulars" :to="{ name: 'Circulars' }">
          <template #icon>
            <post />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Archives" :to="{ name: 'Archives' }">
          <template #icon>
            <archive />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="false">
        <sidenav-collapse navText="Assistance" :to="{ name: 'Assistance' }">
          <template #icon>
            <assistance />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item"  v-if="false">
        <sidenav-collapse navText="Products" :to="{ name: 'Products' }">
          <template #icon>
            <products />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Staff Contacts" :to="{ name: 'Contacts' }">
          <template #icon>
            <contacts />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Policies" :to="{ name: 'Policies' }">
          <template #icon>
            <policy />
          </template>
        </sidenav-collapse>
      </li> 
      <li class="nav-item">
        <sidenav-collapse navText="Holdings Info" :to="{ name: 'Holdings' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Pulse TV" :to="{ name: 'Pulse TV' }">
          <template #icon>
            <tv/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="false">
      <!-- <li class="nav-item" v-if="getViewReportStatus()"> -->
        <sidenav-collapse navText="Statistics" :to="{ name: 'Statistics' }">
          <template #icon>
            <settings/>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="false" class="nav-item">
        <sidenav-collapse navText="RTL" :to="{ name: 'Rtl' }">
          <template #icon>
            <settings />
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="false" class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li>
      <li v-if="false" class="nav-item">
        <sidenav-collapse navText="Profile" :to="{ name: 'Profile' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="false" class="nav-item">
        <sidenav-collapse navText="Sign In" :to="{ name: 'Sign In' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="false" class="nav-item">
        <sidenav-collapse navText="Sign Up" :to="{ name: 'Sign Up' }">
          <template #icon>
            <spaceship />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
//import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import Tv from "../../components/Icon/Tv.vue";
 import Policy from "../../components/Icon/Policy.vue";
import Contacts from "../../components/Icon/Contacts.vue";
import Post from "../../components/Icon/Post.vue";
import Magazine from "../../components/Icon/Magazine.vue";
import Archive from "../../components/Icon/Archive.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import Settings from "../../components/Icon/Settings.vue";
import { mapGetters } from "vuex";
import Products from "@/components/Icon/Products.vue";
import Assistance from "@/components/Icon/Assistance.vue";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    Assistance,
    Products,
    SidenavCollapse,
    // SidenavCard,
    Shop,
    Office,
    // CreditCard,
    // Box3d,
    Contacts,
    Post,
    Tv,
    Magazine,
     Policy,
    Archive,
    CustomerSupport,
    Document,
    Spaceship,
    Settings,
  },
  methods: {
    ...mapGetters(["getViewReportStatus"]),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
  },
  },
};
</script>
