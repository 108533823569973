<template>
  <div class="container-fluid">
    <div
      class="mt-3 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-primary opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <contacts-icon></contacts-icon>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Assistance</h5>
            <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
          </div>
        </div>
        <div  hidden="hidden" class="mx-auto mt-3 col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <p class="text-sm font-weight-bold"><i class="fa fa-search"></i>Search Products</p>
          <soft-input placeholder="Type to search" type="text"  @input="input=>onSearch(input)" />
          <!-- <select class="form-control" name="contacts" id="contacts" :v-model="selectedContact" @change="setSelectedContact($event)">
            <option  value="0">Please Select</option>
            <option v-for="contact in getCurrentContacts()" :value="contact.id" :key="contact.id">{{ contact.contactName }}</option>
          </select> -->
        </div>
        <!-- <div
          class="mx-auto mt-3 col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
         <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <span class="ms-1">Change Request</span>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="mb-4 card">
      <div class="p-3 card-body">
<!--        <div class="table-responsive p-0">
          <p> How Do you want to be assisted? </p>
        <div class="d-flex align-content-center">

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"  onchange="changeCat" v-model="category"  name="inlineRadioOptions" id="inlineRadio1" value="Drafting">
            <label class="form-check-label" for="inlineRadio1">Email Drafting</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"  v-model="category"  name="inlineRadioOptions" id="inlineRadio2" value="Response">
            <label class="form-check-label" for="inlineRadio2">Email Response</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"  v-model="category"  name="inlineRadioOptions" id="inlineRadio3" value="Grammar" >
            <label class="form-check-label" for="inlineRadio3">Grammar Correction</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"  v-model="category"  name="inlineRadioOptions" id="inlineRadio3" value="Assistance" >
            <label class="form-check-label" for="inlineRadio3">General Assistance</label>
          </div>
        </div>
          <div v-if="!showResponse">
          <div class="form-floating form-outline w-100">
            <textarea class="form-control" placeholder="Leave a comment here" v-model="messageContent"  id="floatingTextarea2" style="height: 100px"></textarea>
            <label for="floatingTextarea2">
              <span v-if="category == 'Drafting'">Draft an email</span>
              <span v-if="category == 'Response'">Enter Email Contents to respond to </span>
              <span v-if="category == 'Grammar'">Enter contents to check grammar </span>
              <span v-if="category == 'Assistance'">Enter contents to be assisted on </span>
            </label>
          </div>
          <div class="float-end mt-2 pt-1">
            <button type="button" @click="sendAIRequest" class="btn  button-loading btn-primary btn-sm">
              Submit
            </button>
          </div>
          </div>
          <div v-if="showResponse" class="mt-4 row">


            <div class="mt-3 mb-4 pb-2" v-html="message" >
            </div>
            <div class="small d-flex justify-content-start">
              <a style="cursor: pointer;" @click="copyResponse" class="d-flex align-items-center me-3">
                <i class="far fa-copy me-2"></i>
                <p  class="mb-0">Copy Response</p>
              </a>
              <a  @click="cancelResponse" style="cursor: pointer;"  class="d-flex align-items-center me-3">
                <i class="fas fa-cancel me-2"></i>
                <p class="mb-0">Cancel Response</p>
              </a>

            </div>
          </div>

        </div>-->
       <div class="row g-0">

                <div class="col-12 col-lg-7 col-xl-9">
                  <div class="py-2 px-4 border-bottom d-none d-lg-block">
                    <div class="d-flex align-items-center py-1">

                      <div class="flex-grow-1 pl-3">
                        <strong>How Do you want to be assisted?</strong>
                        <div class="text-muted small">
                          <div class="d-flex align-content-center">

                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio"  checked v-on:change="changeCat" v-model="category"  name="inlineRadioOptions" id="inlineRadio1" value="Drafting">
                              <label class="form-check-label" for="inlineRadio1">Email Drafting</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" v-on:change="changeCat"  v-model="category"  name="inlineRadioOptions" id="inlineRadio2" value="Response">
                              <label class="form-check-label" for="inlineRadio2">Email Response</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio"  v-on:change="changeCat" v-model="category"  name="inlineRadioOptions" id="inlineRadio3" value="Grammar" >
                              <label class="form-check-label" for="inlineRadio3">Grammar Correction</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio"  v-on:change="changeCat"  v-model="category"  name="inlineRadioOptions" id="inlineRadio3" value="Assistance" >
                              <label class="form-check-label" for="inlineRadio3">General Assistance</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="position-relative">

                    <div v-if="messageList.length<0">
                      no message yet
                    </div>
                    <div class="chat-messages p-4">
                        <div  :key="item" v-for="item in messageList"  class="pb-4"  :class="{ 'chat-message-right' : item.sender==='You', 'chat-message-left' : item.sender!=='You' }">
                          <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                            <div class="font-weight-bold mb-1">{{item.sender}}:</div>
                            <div v-html="item.message"/>
                          </div>
                        </div>
                      <div  v-if="messageList.length>1" class="small d-flex justify-content-start">
                        <a style="cursor: pointer;" @click="copyResponse" class="d-flex align-items-center me-3">
                          <i class="far fa-copy me-2"></i>
                          <p  class="mb-0">Copy Response</p>
                        </a>
                        <a  @click="cancelResponse" style="cursor: pointer;"  class="d-flex align-items-center me-3">
                          <i class="fas fa-cancel me-2"></i>
                          <p class="mb-0">Cancel Response</p>
                        </a>

                      </div>

                    </div>
                  </div>

                  <div class="flex-grow-0 py-3 px-4 border-top">
                    <div class="form-floating form-outline w-100">
                      <textarea class="form-control" placeholder="Leave a comment here" v-model="messageContent"  id="floatingTextarea2" style="height: 100px"></textarea>
                      <label for="floatingTextarea2">
                        <span v-if="category == 'Drafting' || category == ''">Draft an email</span>
                        <span v-if="category == 'Response'">Enter Email Contents to respond to </span>
                        <span v-if="category == 'Grammar'">Enter contents to check grammar </span>
                        <span v-if="category == 'Assistance'">Enter contents to be assisted on </span>
                      </label>
                    </div>
                    <div class="float-end mt-2 pt-1">
                      <button type="button" @click="sendAIRequest" class="btn  button-loading btn-primary btn-sm">
                        Submit
                      </button>
                    </div>
                  </div>

                </div>
              </div>



      </div>
    </div>
  </div>
</template>

<script>

import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import perfomance from "@/assets/img/bonus.jpeg";
import coins from "@/assets/img/coins.jpeg";
import prices from "@/assets/img/prices.png";
import job from "@/assets/img/job.png";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {formatDate} from "../utils/index"
import ContactsIcon from "../components/Icon/ContactsIcon.vue";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import SoftInput from "@/components/SoftInput.vue";

import { mapActions, mapGetters } from "vuex";
import Fuse  from "fuse.js"
import axios from "axios";
import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css';

const options = {
  includeScore: true,
  // Search for the following keys
  keys:["id","title" , "description","date_created"],
  threshold: 0.3,
  location: 0,
  distance: 100,
  includeMatches: true,
  useExtendedSearch: true
}

export default {
  name: "ProfileOverview",
  components: {

    SoftInput,

    ContactsIcon
  },
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      perfomance,
      prices,
      job,
      coins,
      faFacebook,
      faTwitter,
      faInstagram,
      contacts:[],
      searchTerm:"",
      searchHits:[],
      messageList:[],
      selectedContact:"0",
      selectedDocumentPath:"",
      selectedContactDescription:"",
      selectedContactName:"",
      perPage:8,
      page:1,
      sortKey:"",
      orderBy:{
        asc:"ASC",
        desc:"DESC"
      },
      category: '',
      messageContent:'',
      message:'',
      showResponse:false,

    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  created(){
    this.fetchProductsAction({access:this.currentUser().access});
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  filters:{

  },
  methods:{
    ...mapActions(["fetchProductsAction"]),
    ...mapGetters(["getCurrentProducts","getBaseUrl","currentUser"]),
    formatDate,
    cancelResponse(){
      this.messageList = []
      this.messageContent =''
    },
    changeCat(){
      this.messageList = []
      this.messageContent =''
    },
    copyResponse(){

      navigator.clipboard.writeText(this.message)
      toast("Text copied", {
        autoClose: 1000,
      });
      //document.execCommand(this.message);
      //clipboard.writeText('Text to get copied');
    },
    sendAIRequest(){
      this.messageList=[]
      this.messageList.push({
        "sender": "You",
        "message":this.messageContent
      })
      console.log(this.category)
      console.log(this.messageContent)
      let messageSend = this.messageContent;
      this.messageContent=''
      axios.get(`/assistance/service/assist/?email_message=${messageSend}&query_type=${this.category}`).then(response => {
          console.log(response)
          this.showResponse = true,
          this.message = response.data.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
        this.messageList.push({
          "sender": "Response",
          "message":this.message
        })
        console.log( this.message);
      })
        .catch(error => {
          this.showResponse = true,
            console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.errorMessage = error.message;
          this.message = error.message
          console.error("There was an error!", error);
        });
    },
    contactsToRender(){
      if(this.searchTerm!=""){
        const searchHitsResult = this.searchHits.map(type=>type.item);
        return searchHitsResult;
      }
      return this.getCurrentProducts()
    },
    setSortBy(key){
      this.sortKey = key
    },
    sortBy(contactsArray){
      if(this.sortKey===""){
        return contactsArray
      }
      const sortedByKey = contactsArray
        .sort((a, b) =>
          (a[this.sortKey] || "").localeCompare((b[this.sortKey]||"")));
      return sortedByKey
    },
    onSearch(event){
      const val = event.target.value;
      const fuse = new Fuse(this.getCurrentProducts(), options);
      const results = fuse.search(val);
      this.searchTerm = val;
      this.searchHits = results;
    },
    nextPage(){
      if(this.page!==Math.ceil(this.contactsToRender()/this.perPage)){
        this.page +=1
      }
    },
    generateList(){
      const numOfpages = Math.ceil(this.contactsToRender().length / this.perPage);
      const pages = [];
      for(let i=0;i<numOfpages;i++){
        pages.push(i)
      }
      return pages
    },
    backPage(){
      if(this.page !==1){
        this.page -=1
      }
    },
    readProduct(item){
      this.$store.state.product = item
      this.$router.push(`/readProduct`);
    },
    goToPage(numPage){
      this.page = numPage
    },
    getPaginatedData(){
      const unSortedProducts = this.contactsToRender()
      const sortedProducts = this.sortBy(unSortedProducts)
      return sortedProducts.slice((this.page-1) * this.perPage,this.page * this.perPage)
    },


  }
};
</script>

<style>
.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow-y: scroll
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}
.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}
.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}
.flex-grow-0 {
  flex-grow: 0!important;
}
.border-top {
  border-top: 1px solid #dee2e6!important;
}

</style>


