<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }},
            <img :src="xlogo" style="height: 100px;width: 100px;" />
          </div>
        </div>
        <div class="col-lg-5">
          <ul
            v-if="isAuthenticated()"
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://hrms.fbc.co.zw/"
                class="nav-link text-muted"
                target="_blank"
                >HRMS</a
              >
            </li>
            <li class="nav-item">
              <a
                href="http://10.170.8.32:8080"
                class="nav-link pe-0 text-muted"
                target="_blank"
                >Helpdesk</a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </footer>
</template>

<script>
import xlogo from "@/assets/img/xarani.png";
import { mapGetters  } from "vuex";
export default {
  name: "app-footer",
  methods:{
    ...mapGetters(["isAuthenticated"])
  },
  data:()=>{
    return {
      xlogo
    }
  }
};
</script>
