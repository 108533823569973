<template>
    <div class="mb-4 col-xl-3 col-md-4  mb-xl-0 mt-4 pe-auto" >
      <div class="card card-blog card-plain">
        <div class="position-relative">
          <a 
          >
            <img
              :src="image"
              alt="img-blur-shadow"
              height="200"
              class="shadow img-fluid2 border-radius-xl"
            />
           
          </a>
        </div>
        <div class="px-1 pb-0 card-body">
          <!-- <p class="mb-2 text-sm text-gradient text-dark">{{ label }}</p> -->
          <a href="javascript:;"
          @click="goTo()"
          >
            <h5>{{ title }}</h5>
          </a>
          <p class="mb-4 text-sm">
            {{ description }}
          </p>
          <div class="align-items-center justify-content-between">
            <button
              v-if="false"
              type="button"
              class="mb-0 btn btn-sm"
              :class="`btn-outline-${action.color}`"
              @click="viewProfile()"
            >
              {{ action.label }}
            </button>
  
            <div class="mt-2 avatar-group">
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ExecutiveCard",
    props: {
      image: {
        type: String,
        default: "",
      },
      item:{
        type: Object,
        default:()=>{}
      },
      label: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "Default Project Card",
      },
      description: {
        type: String,
        default: "",
      },
      action: {
        type: Object,
        route: String,
        color: String,
        label: String,
      },
    },
    components:{
    },
    methods: {
      created(){
      },
      viewProfile(){
        this.$emit('view-profile',this.item)
      },
      data:()=>{
        return {

        }
      },
    }
  };
  </script>
  