<template>
  <div v-if="modalOpen" class="modal fade show"
       tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog"
       style="display:block">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">
            Update SBU Details
          </h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <select v-model="sbu_id" class="form-control" name="contacts" id="contacts">
                <option value="0">Please Select</option>
                <option v-for="sbu in sbuOptions" :value="sbu.id" :key="sbu.id">{{ sbu.sbu_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="btn btn-primary ml-5" @click="saveSBUsDetails()">Submit Details</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row my-0">
      <div class="col-lg-8 col-md-8 mb-md-0 mb-6" style="height: 300px;">
        <carousel />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Posts overview"
          :description=makeDescription()
        >
          <div v-for="item in this.getTodaysPosts().slice(0,5)" :key="item.id">
          <timeline-item
            color="primary"   
            icon="bell-55"
            :title=item.title
            :date-time=formatDate(item.date_created)
            :item="item"
          />
          </div>
        </timeline-list>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <authors-table />
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div>Previous Circulars/Articles</div>
      <div class="row">
        <default-project-card
        v-for="item in this.getPaginatedData()" :key="item.pk"
          :title="item.title"
          class="card-post"
          :image="getPostImage(item.category_id.post_artwork)"
          :description="item.description"
          :post_id="item.pk"
          :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
          :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
        />
        <div class="d-flex justify-content-center mt-3">
                <soft-pagination color="success">
                  <soft-pagination-item prev @click="backPage"/>       
                  <soft-pagination-item v-for="item in Math.ceil(posts.length / perPage)" 
                  :active="page===item"
                :key="item" :label="`${item}`"   @click="goToPage(item)"/>
                  <soft-pagination-item next @click="nextPage" />
                </soft-pagination>
        </div>

        </div>
    </div>
    <div class="row" v-if="false">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Today's Money"
          value="$53,000"
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Today's Users"
          value="2,300"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: ' ni ni-world',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="New Clients"
          value="+3,462"
          :percentage="{
            value: '-2%',
            color: 'text-danger',
          }"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card
          title="Sales"
          value="$103,430"
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-cart',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
    </div>
    <div class="row" v-if="false">
      <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Built by developers</p>
                  <h5 class="font-weight-bolder">Soft UI Dashboard</h5>
                  <p class="mb-5">
                    From colors, cards, typography to complex elements, you will
                    find the full documentation.
                  </p>
                  <a
                    class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                    href="javascript:;"
                  >
                    Read More
                    <i
                      class="fas fa-arrow-right text-sm ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-success border-radius-lg h-100">
                  <img
                    src="../assets/img/shapes/waves-white.svg"
                    class="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                    alt="waves"
                  />
                  <div
                    class="position-relative d-flex align-items-center justify-content-center h-100"
                  >
                    <img
                      class="w-100 position-relative z-index-2 pt-4"
                      src="../assets/img/illustrations/rocket-white.png"
                      alt="rocket"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Work with the rockets
                </h5>
                <p class="text-white mb-5">
                  Wealth creation is an evolutionarily recent positive-sum game.
                  It is all about who take the opportunity first.
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  href="javascript:;"
                >
                  Read more
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row" v-if="false">
      <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            <reports-bar-chart
              id="chart-bar"
              title="active Users"
              description="(<strong>+23%</strong>) than last week"
              :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: {
                  label: 'Sales',
                  data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
                },
              }"
              :items="[
                {
                  icon: {
                    color: 'primary',
                    component: faUsers,
                  },
                  label: 'users',
                  progress: { content: '37K', percentage: 60 },
                },
                {
                  icon: { color: 'info', component: faHandPointer },
                  label: 'clicks',
                  progress: { content: '2m', percentage: 90 },
                },
                {
                  icon: { color: 'warning', component: faCreditCard },
                  label: 'Sales',
                  progress: { content: '435$', percentage: 30 },
                },
                {
                  icon: { color: 'danger', component: faScrewdriverWrench },
                  label: 'Items',
                  progress: { content: '43', percentage: 50 },
                },
              ]"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <!-- line chart -->
        <div class="card z-index-2">
          <gradient-line-chart
            id="chart-line"
            title="Gradient Line Chart"
            description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>4% more</span> in 2021"
            :chart="{
              labels: [
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              datasets: [
                {
                  label: 'Mobile Apps',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
                {
                  label: 'Websites',
                  data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                },
              ],
            }"
          />
        </div>
      </div>
    </div>
    <div class="row my-4" v-if="false">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <projects-card></projects-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            color="success"
            icon="bell-55"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="danger"
            icon="html5"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="cart"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="credit-card"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="key-25"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
          />
          <TimelineItem
            color="info"
            icon="check-bold"
            title="Notifications unread"
            date-time="15 DEC"
          />
        </timeline-list>
      </div>
    </div>
    <div v-if="false" class="row my-4">
      <div class="col-lg-8 col-md-8 mb-md-0 mb-6">
        <carousel />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            color="success"
            icon="bell-55"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="danger"
            icon="html5"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="cart"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="credit-card"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="key-25"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
          />
          <TimelineItem
            color="info"
            icon="check-bold"
            title="Notifications unread"
            date-time="15 DEC"
          />
        </timeline-list>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import Carousel from "./components/Carousel.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import TimelineList from "./components/TimelineList.vue";
import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import perfomance from "@/assets/img/bonus.jpeg";
import coins from "@/assets/img/coins.jpeg";
import prices from "@/assets/img/prices.png";
import job from "@/assets/img/job.png";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import ProjectsCard from "@/views/components/ProjectsCard";
import AuthorsTable from "@/views/components/AuthorsTable";
import DefaultProjectCard from "@/views/components/DefaultProjectCard";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import { mapActions, mapGetters } from "vuex";
import {formatDate} from "../utils/index"
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import axios from "axios";
export default {
  name: "dashboard-default",
  created(){
    this.fetchPostsAction({access:this.currentUser().access,department: this.currentUser().department});
    this.fetchPostLogsAction({access:this.currentUser().access});
    this.fetchLatestPostsAction({access:this.currentUser().access});
    // this.getBannersAction({access: this.currentUser().access});
    this.posts = this.currentPosts();
    // this.fetchUserSBU();
    // this.getSBUsOptions();
  },
  methods:{
    formatDate,
    ...mapGetters([
      "currentPosts",
      "getTodaysPosts",
      "getBaseUrl",
      "currentUser"
    ]),
    ...mapActions(["fetchPostsAction","fetchPostLogsAction","fetchLatestPostsAction","getBannersAction"]),
    makeDescription(){
      return `<i class='fa fa-bell text-primary' aria-hidden='true'></i>
        <span class='font-weight-bold'> ${this.getTodaysPosts().length} posts </span> today`
    },
    getPostImage(imageUrl){
      if(!imageUrl){
        return job
      }
      const str = this.getBaseUrl()
      if(str.charAt(str. length - 1)==="/"){
        return str.slice(0, -1) + imageUrl
      }
      return this.getBaseUrl() + imageUrl
    },
    nextPage(){
        if(this.page!==Math.ceil(this.posts.length/this.perPage)){
          this.page +=1 
        }
    },
    backPage(){
      if(this.page !==1){
        this.page -=1
      }
    },
    goToPage(numPage){
      this.page = numPage
    },
    getPaginatedData(){
      return this.currentPosts().slice((this.page-1) * this.perPage,this.page * this.perPage)
    },
    async fetchUserSBU(){
      try{
        const access = this.currentUser().access
        const user = this.currentUser();
        const {user_id} = user;
        const response = await axios.get(`intranet/posts/fetch_user_sbu/?user_id=${user_id}`,{
          headers:{
            Authorization:`Bearer ${access}`
          }
        });
        if(response.status === 200){
          if(response.data[0]['status'] === "404") {
            this.openCloseFun();
          }
        }
      }catch(error){
        console.log(error)
      }
    },
    async saveSBUsDetails(){
      try{
        const access = this.currentUser().access
        const user = this.currentUser();
        const {user_id} = user;
        const sbu_id = this.sbu_id;
        const payload ={
          user_id: user_id,
          sbu_id: sbu_id
        };
        const response = await axios.post(`intranet/posts/add_user_sbu/`, payload, {
          headers:{
            Authorization:`Bearer ${access}`
          }
        });
        if(response.status === 200){
            this.openCloseFun();
        }
      }catch(error){
        console.log(error)
      }
    },
    async getSBUsOptions() {
      try{
        const access = this.currentUser().access
        const response = await axios.get(`intranet/posts/fetch_sbus/`,{
          headers:{
            Authorization:`Bearer ${access}`
          }
        });
        if(response.status === 200){
          this.sbuOptions = response.data
        }
      }catch(error){
        console.log(error)
      }
    },
    openCloseFun(){
      this.modalOpen = !this.modalOpen;
    },
  },
  data() {
    return {
      iconBackground: "bg-gradient-primary",
      faCreditCard,
      posts: [],
      perPage: 8,
      page: 1,
      modalOpen: false,
      sbuOptions: [],
      sbu_id: 0,
      faScrewdriverWrench,
      faUsers,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      perfomance,
      prices,
      job,
      coins,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faHandPointer,

      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    ProjectsCard,
    Carousel,
    MiniStatisticsCard,
    ReportsBarChart,
    GradientLineChart,
    AuthorsTable,
    DefaultProjectCard,
    // ProjectsCard,
    TimelineList,
    TimelineItem,
    SoftPagination, SoftPaginationItem
  },
};
</script>


<style>
  .card-post {
    padding: 0.5rem;
  }
  /*class="card card-blog card-plain"*/
  .card-post:hover {
    /* change the background color when hovering
   text-decoration: underline; */
    /*cursor: pointer;*/
    /*font-weight: bold;*/
    /*word-wrap: break-word;*/
    /*background-clip: border-box;*/
    /*border: 1px solid #00000020;*/
    /*border-radius: 0.8rem;*/
    background: #f1f1f1;
    /*color:#0065b5;*/
  }
</style>