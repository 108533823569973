<template>
    <div>
      <div v-if="modalOpen" class="modal fade show"
           tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog"
           style="display:block">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title">
                Policy Agreement Confirmation
              </h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <p>
                  I hereby confirm that I have thoroughly read and understood the {{ policy.policyName }} policy.
                  I acknowledge and agree to abide by all the terms and conditions stated within this policy.
                </p>
              </div>
              <div class="row">
                <label>
                  <input type="checkbox" v-model="isChecked">
                  I agree to the terms and conditions.
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <div style="margin-right:490px">
                <button class="btn btn-primary ml-5" :disabled="!isChecked" @click="acceptReadPolicy()">Submit Confirmation</button>
              </div>
              <div>
                <button  @click="openCloseFun()" class="btn btn-danger" >Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container border-bottom">
          <div class="col-lg-12 row justify-space-between py-2 container border-bottom">
            <div class="col-lg-8 me-auto">
              <h3 class="lead text-dark pt-1 bold mb-1">
                {{ policy.policyName }}
              </h3>
            </div>
            <div class="col-lg-2">
              <!-- isInstructionCircular -->
              <a
                  class="btn w-100"
                  :class="buttonClass()"
                  @click="openCloseFun()"
              >{{ getStatusText() }}</a>
            </div>
            <div class="row">
              {{ policy.policyDescription || "" }}
            </div>
          </div>
        <div>
          <web-viewer
            v-if="policy.policyAttachment && blobContent"
            :initialDoc="getUrlPath()"
            :docId="policy.id"
            :data="blobContent"
            :username="currentUser().username"
            :extension="blobMimeExt"
          />     
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import {mapActions, mapGetters} from "vuex";
  import axios from "axios";
  import {formatDate} from "../utils/index"
  import WebViewer from "./components/WebViewer.vue";

  export default {
    name: "Read",
    components:{
      WebViewer
    },
      created() {
       this.policy = this.getCurrentPolicy();
       this.getDocumentBlob(this.policy.id);
       this.viewedPolicyLog(this.policy.id);
       this.getPolicyReadLogs();
     },
  
    methods: {
      ...mapGetters(["getCurrentPolicy","getBaseUrl","currentUser","getPostLogs"]),
      ...mapActions(["fetchPostAdditionalAttachmentsAction"]),
      formatDate,
      getUrlPath(){
        return ""
      },
        base64ToBlob(base64,mimeType) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
              bytes[i] = binaryString.charCodeAt(i);
            }
  
        return new Blob([bytes], { type: mimeType });
      },
        async getDocumentBlob(policy_id){
        const accessToken = this.$store.getters.currentUser.access
        const res = await axios.get(`intranet/posts/fetch_policies_blob/?policy_id=${policy_id}`,{ headers:{
                Authorization:`Bearer ${accessToken}`
        } });
        const {data} = res
        const mimeType = data.mimeType
        const blobRes =this.base64ToBlob(data.documentBase,mimeType);
        console.log({blobRes,ext:data.extension})
        this.blobContent = blobRes;
        this.blobMimeExt = data.extension;
        },
        async viewedPolicyLog(policy_id){
        const accessToken = this.$store.getters.currentUser.access
        const {user_id} = this.currentUser();

        const payload = {
          policy_id:policy_id,
          user_id:user_id,
          isViewed:true
        }
        const res = await axios.post(`intranet/posts/add_policy_log/`, payload, { headers:{
            Authorization:`Bearer ${accessToken}`
          } });
        const {data} = res
          console.log(data);
      },
      async getPolicyReadLogs(){
        try{
          const access = this.currentUser().access
          const response = await axios.get(`intranet/posts/fetch_policy_logs/`,{
            headers:{
              Authorization:`Bearer ${access}`
            }
          });
          if(response.status === 200){
            const policyReadLogs = response.data;
            this.policyReadLogs = policyReadLogs;
            // console.log("Policy Read Logs", response.data);
          }
        }catch(error){
          console.log(error)
        }
      },
      // getPolStatus(){
      //   const policy_id = this.policy.id
      //   const {user_id} = this.currentUser();
      //   const policy_log = this.policyReadLogs.find(lg => lg.policy_id === policy_id)
      //   console.log("PL Log", policy_log);
      //   if(policy_log && policy_log.user_id === user_id && policy_log.isRead === true){
      //     // console.log("Policy is read")
      //     return true
      //   }
      // },
      getPolicyStatus(){
        const policy_id = this.policy.id
        const {user_id} = this.currentUser();
        const policy_log = this.policyReadLogs.find(lg => lg.policy_id === policy_id && lg.user_id === user_id)
        if(policy_log && policy_log.isRead === true){
            return true
        }
      },
      buttonClass() {
        return this.getPolicyStatus() ? 'bg-gradient-success' : 'bg-gradient-danger';
      },
      getStatusText(){
        return this.getPolicyStatus() ? "Accepted Policy": "Accept Policy"
      },
      async acceptReadPolicy(){
        try{
          const policy_id = this.policy.id
          const user = this.currentUser();
          const {user_id} = user;
          const payload ={
            policy_id:policy_id,
            user_id:user_id,
            isRead:true
          };
          const access = this.currentUser().access
          const response = await axios.patch(`intranet/posts/patch_policy_log/`,payload,{
            headers:{
              Authorization:`Bearer ${access}`
            }
          });
          if(response.status === 200){
            this.openCloseFun();
            this.goBack()
            // console.log({response});
          }
        }catch(error){
          console.log(error);
        }
        // window.location.reload()
      },
      openCloseFun(){
        this.modalOpen = !this.modalOpen;
      },
      goBack(){
        this.$router.go(-1);
      },
    },
    data:()=>{
      return {
        policy:{},
        policyReadLogs:[],
        postAttachments:[],
        modalOpen:false,
        isChecked: false,
        comment_text:"",
        postComments:[],
        blobContent:null,
        blobMimeExt:null,
      }
    }
    
  };
  </script>
  