<template>
  <div class="overflow-hidden h-100 p-0">
    <div id="carouselExampleCaptions" class="carousel slide h-100" data-bs-ride="carousel">
      <div class="carousel-inner border-radius-lg h-100">
        <div
          class="carousel-item h-100 active"
        >
            <img style="width: 100%; height: 100%;object-fit: contain; border-radius: 4px;" 
               :src="getPostImage()"
            alt="">
        </div>
        <!-- <div
          v-else
          class="carousel-item h-100 active"
          :style="{backgroundImage: 'url(' + require('@/assets/img/Pic3.png') + ')',
      backgroundSize: 'cover'}"
        >
          <div class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
          </div>
        </div> -->
      </div>
      <button
        class="carousel-control-prev w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
        @click="prev()"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
        @click="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import axios from "axios"
import bannerImg from "@/assets/img/Pic3.jpg"
export default {
  name: "carousel",
  data(){
    return {
      selectedBannerIndex:0,
      banners:[],
      interval:null
    }
  },

  created(){
     this.getBanners()
     this.interval =  setInterval(()=>{
       this.next();
    },5000);
  },
  beforeUnmount(){
    clearInterval(this.interval)
  },
  methods:{
    ...mapGetters(["currentUser","getBaseUrl"]),
    next(){
      if(this.selectedBannerIndex+1 < this.banners.length){
        const newIdx = this.selectedBannerIndex + 1
        this.selectedBannerIndex = newIdx
      }else{
        this.selectedBannerIndex = 0
      }
    },
    prev(){
      if(this.selectedBannerIndex!=0){
        this.selectedBannerIndex = this.selectedBannerIndex -1
      }else{
        this.selectedBannerIndex = this.banners.length-1
      }
    },
    getPostImage(){
      const image = this.banners[this.selectedBannerIndex];
      if(!image){
        return bannerImg;
      }
      return `data:image/png;base64,${image.base64}`
    },
    async getBanners(){
      try{
      const response = await axios.get("/intranet/posts/fetch_ad_imgs/",
      {
        headers:{
          Authorization: `Bearer ${this.currentUser().access}`
        }
      })
      if(response.status == 200){
        this.banners = response.data.bannersList.filter(b=>b.active=="Active");
      }
    }catch(e){
      console.log({e})
    }
    }
  }
};
</script>
