<template>
  <div class="container-fluid">
    <div
      class="mt-3 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-primary opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <contacts-icon></contacts-icon>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Products</h5>
            <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <p class="text-sm font-weight-bold"><i class="fa fa-search"></i>Search Products</p>
          <soft-input placeholder="Type to search" type="text"  @input="input=>onSearch(input)" />
          <!-- <select class="form-control" name="contacts" id="contacts" :v-model="selectedContact" @change="setSelectedContact($event)">
            <option  value="0">Please Select</option>
            <option v-for="contact in getCurrentContacts()" :value="contact.id" :key="contact.id">{{ contact.contactName }}</option>
          </select> -->
        </div>
        <!-- <div
          class="mx-auto mt-3 col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
         <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <span class="ms-1">Change Request</span>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="mb-4 card">
      <div class="p-3 card-body">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
            <tr>
<!--              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                style="text-align: left;"
              >
                ID
                <i class="fas fa-duotone fa-sort"
                   :class="sortKey==='contact_parent' ?  'fa-2x':''"
                   @click="setSortBy('contact_parent')"></i>
              </th>-->
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                style="text-align: left;"
              >
                Title
                <i class="fas fa-duotone fa-sort"></i>
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  style="text-align: right;">
                Created
                <i class="fas fa-duotone fa-sort"></i>
              </th>

            </tr>
            </thead>
            <tbody v-for="item in getPaginatedData()" :key="item.id">
            <tr @click="readProduct(item)">

              <td style="text-align: left;">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.title  }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </td>
              <td  style="text-align: right;" class="align-right">
                <span class="text-secondary text-xs font-weight-bold">
                  {{ formatDate(item.date_created) }}
                </span>

              </td>

            </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center mt-3">
            <soft-pagination color="success">
              <soft-pagination-item prev @click="backPage"/>
              <soft-pagination-item v-for="item in generateList().slice(page,page+perPage)"
                                    :active="page===item"
                                    :key="item" :label="item.toString()"   @click="goToPage(item)"/>
              <soft-pagination-item next @click="nextPage" />
            </soft-pagination>
          </div>
          <div v-if="false" class="mt-3 rounded row">
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card h-100">
                <div class="p-3 pb-0 card-header">
                  <h6 class="mb-0">Platform Settings</h6>
                </div>
                <div class="p-3 card-body">
                  <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                    Account
                  </h6>
                  <ul class="list-group">
                    <li class="px-0 border-0 list-group-item">
                      <soft-switch
                        id="flexSwitchCheckDefault"
                        name="email"
                        class="ps-0 ms-auto"
                        label-class="mb-0 text-body ms-3 text-truncate w-80"
                        checked
                      >Email me when someone follows me</soft-switch
                      >
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      <soft-switch
                        id="flexSwitchCheckDefault1"
                        name="Email"
                        label-class="mb-0 text-body ms-3 text-truncate w-80"
                        class="ps-0 ms-auto"
                      >Email me when someone answers on my post</soft-switch
                      >
                    </li>

                    <li class="px-0 border-0 list-group-item">
                      <soft-switch
                        id="flexSwitchCheckDefault2"
                        name="Email"
                        label-class="mb-0 text-body ms-3 text-truncate w-80"
                        class="ps-0 ms-auto"
                        checked
                      >Email me when someone mentions me</soft-switch
                      >
                    </li>
                  </ul>
                  <h6
                    class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
                  >
                    Application
                  </h6>
                  <ul class="list-group">
                    <li class="px-0 border-0 list-group-item">
                      <soft-switch
                        id="flexSwitchCheckDefault3"
                        name="Project Launch"
                        class="ps-0 ms-auto"
                        label-class="mb-0 text-body ms-3 text-truncate w-80"
                      >New launches and projects</soft-switch
                      >
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      <soft-switch
                        id="flexSwitchCheckDefault4"
                        name="Product Update"
                        class="ps-0 ms-auto"
                        label-class="mb-0 text-body ms-3 text-truncate w-80"
                        checked
                      >Monthly product updates</soft-switch
                      >
                    </li>
                    <li class="px-0 pb-0 border-0 list-group-item">
                      <soft-switch
                        id="flexSwitchCheckDefault5"
                        name="Newsletter"
                        class="ps-0 ms-auto"
                        label-class="mb-0 text-body ms-3 text-truncate w-80"
                      >Subscribe to newsletter</soft-switch
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
              <profile-info-card
                title="Profile Information"
                description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA',
          }"
                :social="[
            {
              link: 'https://www.facebook.com/CreativeTim/',
              icon: faFacebook,
            },
            {
              link: 'https://twitter.com/creativetim',
              icon: faTwitter,
            },
            {
              link: 'https://www.instagram.com/creativetimofficial/',
              icon: faInstagram,
            },
          ]"
                :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
              />
            </div>
            <div class="mt-4 col-12 col-xl-4 mt-xl-0">
              <div class="card h-100">
                <div class="p-3 pb-0 card-header">
                  <h6 class="mb-0">Conversations</h6>
                </div>
                <div class="p-3 card-body">
                  <ul class="list-group">
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <soft-avatar
                        class="me-3"
                        :img="sophie"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Sophie B.</h6>
                        <p class="mb-0 text-xs">Hi! I need more information..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                      >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <soft-avatar
                        class="me-3"
                        :img="marie"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Anne Marie</h6>
                        <p class="mb-0 text-xs">Awesome work, can you..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                      >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <soft-avatar
                        class="me-3"
                        :img="ivana"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Ivanna</h6>
                        <p class="mb-0 text-xs">About files I can..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                      >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <soft-avatar
                        class="me-3"
                        :img="peterson"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Peterson</h6>
                        <p class="mb-0 text-xs">Have a great afternoon..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                      >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 border-0 list-group-item d-flex align-items-center"
                    >
                      <soft-avatar
                        class="me-3"
                        :img="nick"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Nick Daniel</h6>
                        <p class="mb-0 text-xs">Hi! I need more information..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                      >Reply</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 row">
            <div class="col-12">
              <div class="mb-4 card">
                <!-- <div v-if="false" class="p-3 pb-0 card-header">
                  <h6 class="mb-1">Projects</h6>
                  <p class="text-sm">Architects design houses</p>
                </div>
                <div class="m-4  row">
                  <p class="text-sm font-weight-bold">{{ selectedContactName }}</p>

                  <p class="text-sm">{{ selectedContactDescription }}</p>
                  <embed v-bind:src="selectedDocumentPath"
                         width="100%"
                         height="900">
                </div> -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import perfomance from "@/assets/img/bonus.jpeg";
import coins from "@/assets/img/coins.jpeg";
import prices from "@/assets/img/prices.png";
import job from "@/assets/img/job.png";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {formatDate} from "../utils/index"
import ContactsIcon from "../components/Icon/ContactsIcon.vue";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import SoftInput from "@/components/SoftInput.vue";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import { mapActions, mapGetters } from "vuex";
import Fuse  from "fuse.js"

const options = {
  includeScore: true,
  // Search for the following keys
  keys:["id","title" , "description","date_created"],
  threshold: 0.3,
  location: 0,
  distance: 100,
  includeMatches: true,
  useExtendedSearch: true
}

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    SoftAvatar,
    SoftInput,
    SoftPagination,
    SoftPaginationItem,
    ContactsIcon
  },
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      perfomance,
      prices,
      job,
      coins,
      faFacebook,
      faTwitter,
      faInstagram,
      contacts:[],
      searchTerm:"",
      searchHits:[],
      selectedContact:"0",
      selectedDocumentPath:"",
      selectedContactDescription:"",
      selectedContactName:"",
      perPage:8,
      page:1,
      sortKey:"",
      orderBy:{
        asc:"ASC",
        desc:"DESC"
      }
    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  created(){
    this.fetchProductsAction({access:this.currentUser().access});
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  filters:{

  },
  methods:{
    ...mapActions(["fetchProductsAction"]),
    ...mapGetters(["getCurrentProducts","getBaseUrl","currentUser"]),
    formatDate,
    contactsToRender(){
      if(this.searchTerm!=""){
        const searchHitsResult = this.searchHits.map(type=>type.item);
        return searchHitsResult;
      }
      return this.getCurrentProducts()
    },
    setSortBy(key){
      this.sortKey = key
    },
    sortBy(contactsArray){
      if(this.sortKey===""){
        return contactsArray
      }
      const sortedByKey = contactsArray
        .sort((a, b) =>
          (a[this.sortKey] || "").localeCompare((b[this.sortKey]||"")));
      return sortedByKey
    },
    onSearch(event){
      const val = event.target.value;
      const fuse = new Fuse(this.getCurrentProducts(), options);
      const results = fuse.search(val);
      this.searchTerm = val;
      this.searchHits = results;
    },
    nextPage(){
      if(this.page!==Math.ceil(this.contactsToRender()/this.perPage)){
        this.page +=1
      }
    },
    generateList(){
      const numOfpages = Math.ceil(this.contactsToRender().length / this.perPage);
      const pages = [];
      for(let i=0;i<numOfpages;i++){
        pages.push(i)
      }
      return pages
    },
    backPage(){
      if(this.page !==1){
        this.page -=1
      }
    },
    readProduct(item){
      this.$store.state.product = item
      this.$router.push(`/readProduct`);
    },
    goToPage(numPage){
      this.page = numPage
    },
    getPaginatedData(){
      const unSortedProducts = this.contactsToRender()
      const sortedProducts = this.sortBy(unSortedProducts)
      return sortedProducts.slice((this.page-1) * this.perPage,this.page * this.perPage)
    },


  }
};
</script>
