<template>
  <div class="container-fluid">
    <div class="mt-3 page-header min-height-200 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '50%',
    }">
      <span class="mask bg-gradient-primary opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <circular-icon></circular-icon>
            <!-- <img src="@/assets/img/fbc.png" alt="profile_image" class="shadow-sm w-100 border-radius-lg" /> -->
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Circulars</h5>
            <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-7 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative end-0">
            <div class="mx-auto mt-3 col-lg-6  col-md-6 my-sm-auto ms-sm-auto me-sm-0">
              <p class="text-sm font-weight-bold">Select Circular Category</p>
              <select class="form-control" name="contacts" id="contacts" @change="handleTabClick($event)">
                <option value="0">Please Select</option>
                <option v-for="category in this.getCategoryOptions()" :value="category.id" :key="category.id">{{
                  category.name }}</option>
              </select>
            </div>
            <!-- <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li 
              v-for="category in this.getCategoryOptions()" 
              :key="category.id"
              class="nav-item">
                <a 
                @click="handleTabClick(category)"
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  role="tab"
                  :aria-selected="selectedFilterCategory===category.name"
                >
                  <span class="ms-1" >{{ category.name }}</span>
                </a>
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <span class="ms-1">Instructional</span>
                </a> 
              </li>
    
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div v-if="false" class="mt-3 row">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Platform Settings</h6>
          </div>
          <div class="p-3 card-body">
            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              Account
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault" name="email" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Email me when someone follows
                  me</soft-switch>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault1" name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto">Email me when someone answers
                  on my post</soft-switch>
              </li>

              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault2" name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto" checked>Email me when someone
                  mentions me</soft-switch>
              </li>
            </ul>
            <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
              Application
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault3" name="Project Launch" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80">New launches and projects</soft-switch>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault4" name="Product Update" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Monthly product updates</soft-switch>
              </li>
              <li class="px-0 pb-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault5" name="Newsletter" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80">Subscribe to newsletter</soft-switch>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <profile-info-card title="Profile Information"
          description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA'
          }" :social="[
  {
    link: 'https://www.facebook.com/CreativeTim/',
    icon: faFacebook,
  },
  {
    link: 'https://twitter.com/creativetim',
    icon: faTwitter,
  },
  {
    link: 'https://www.instagram.com/creativetimofficial/',
    icon: faInstagram,
  },
]" :action="{
  route: 'javascript:;',
  tooltip: 'Edit Profile',
}" />
      </div>
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Conversations</h6>
          </div>
          <div class="p-3 card-body">
            <ul class="list-group">
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="sophie" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Sophie B.</h6>
                  <p class="mb-0 text-xs">Hi! I need more information..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="marie" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Anne Marie</h6>
                  <p class="mb-0 text-xs">Awesome work, can you..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="ivana" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Ivanna</h6>
                  <p class="mb-0 text-xs">About files I can..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="peterson" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Peterson</h6>
                  <p class="mb-0 text-xs">Have a great afternoon..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="nick" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Nick Daniel</h6>
                  <p class="mb-0 text-xs">Hi! I need more information..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4">
          <div v-if="false" class="p-3 pb-0 card-header">
            <h6 class="mb-1">Projects</h6>
            <p class="text-sm">Architects design houses</p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <default-project-card v-for="item in getPaginatedData()" :key="item.id" 
                :title="item.title"
                :image="getImage(item.category_id.post_artwork)" 
                :label="item.category_id.description"
                :description="item.description" 
                :post_id="item.pk" 
                class="card-post"
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]" :action="{
                    color: 'primary',
                    label: 'View Post',
                    }" />
            </div>
            <div class="d-flex justify-content-center mt-3">
              <!-- <soft-pagination color="success">
                <soft-pagination-item prev @click="handlePrev"/>
                <soft-pagination-item v-for="page in numberOfPages()" :label=" +page + 1"  :key="page" :active="currentPage==page" @click="handlePageCLick(page)"/>
                <soft-pagination-item next @click="handleNext" />
              </soft-pagination> -->
              <soft-pagination color="success">
                <soft-pagination-item prev @click="backPage" />
                <soft-pagination-item v-for="item in Math.ceil(this.circularPosts.length / perPage)" :active="page === item"
                  :key="item" :label="item" @click="goToPage(item)" />
                <soft-pagination-item next @click="nextPage" />
              </soft-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import CircularIcon from "../components/Icon/CircularIcon.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import perfomance from "@/assets/img/bonus.jpeg";
import coins from "@/assets/img/coins.jpeg";
import prices from "@/assets/img/prices.png";
import job from "@/assets/img/job.png";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import DefaultProjectCard from "./components/DefaultProjectCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    CircularIcon,
    SoftAvatar,
    DefaultProjectCard,
    SoftPagination, SoftPaginationItem
  },
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      perfomance,
      prices,
      job,
      coins,
      faFacebook,
      faTwitter,
      faInstagram,
      circularPosts: [],
      allCircularPosts: [],
      selectedFilterCategory: null,
      page: 1,
      perPage: 8
    };
  },
  methods: {
    ...mapGetters(["currentCircularPosts", "getPostCategories", "getBaseUrl","currentUser"]),
    ...mapActions(["fetchPostsAction","fetchPostLogsAction","fetchCategoriesAction"]),
    ...mapMutations(["fetchPostLogs", "fetchPosts", "fetchCategories"]),
    getCategoryOptions() {
      return [{ 'id': -1, 'description': "N/A", 'name': 'ALL' }, ...this.getPostCategories()]
    },
    getLabel(category_id) {
      const categories = this.getPostCategories()
      const category = categories.find(obj => obj.id == category_id)
      return category !== undefined ? category.name : "N/A"
    },
    getImage(category_id) {
      return this.getPostArtWork(category_id)
    },
    getPostArtWork(imageUrl) {
      if(!imageUrl){
        return this.perfomance
      }
      const str = this.getBaseUrl()
      if(str.charAt(str. length - 1)==="/"){
        return str.slice(0, -1) + imageUrl
      }
      return this.getBaseUrl() + imageUrl

    },
    handleTabClick(event) {
      const category = this.getCategoryOptions().find(c => c.id == event.target.value)
      if (category.name == "ALL") {
        this.circularPosts = this.allCircularPosts;
        this.selectedFilterCategory = "ALL"
      } else {
        const filterByTab = this.allCircularPosts.filter(post => post.category_id.id === category.id)
        this.selectedFilterCategory = category.name
        this.circularPosts = filterByTab
      }
    },
    nextPage() {
      if (this.page !== Math.ceil(this.circularPosts.length / this.perPage)) {
        this.page += 1
      }
    },
    backPage() {
      if (this.page !== 1) {
        this.page -= 1
      }
    },
    goToPage(numPage) {
      this.page = numPage
    },
    getPaginatedData() {
      return this.circularPosts.slice((this.page - 1) * this.perPage, this.page * this.perPage)
    },
  },
  
    created() {
      this.fetchPostsAction({access:this.currentUser().access, department: this.currentUser().department});
      this.fetchPostLogsAction({access:this.currentUser().access});
      this.fetchCategoriesAction({access:this.currentUser().access});
      const circularPosts = this.currentCircularPosts();
      this.allCircularPosts = circularPosts;
      this.circularPosts = circularPosts;
    },
    mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
}
</script>


<style>
.card-post {
  padding: 0.5rem;
}
/*class="card card-blog card-plain"*/
.card-post:hover {
  /* change the background color when 
 text-decoration: underline; */
  /*cursor: pointer;*/
  /*font-weight: bold;*/
  /*word-wrap: break-word;*/
  /*background-clip: border-box;*/
  /*border: 1px solid #00000020;*/
  /*border-radius: 0.8rem;*/
  background: #f1f1f1;
  /*color:#0065b5;*/
}
</style>