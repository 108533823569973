<template>
  <div>
    <soft-alert v-if="alert.showAlert" style="z-index: 9999;" :color="alert.color">
        {{ alert.message }}
      </soft-alert>
    <div v-if="modalOpen" class="modal fade show" 
    tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" 
    style="display:block">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h5 class="modal-title">
              {{ postComments.length }} Comments
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
                  <div class="col">
                    <input placeholder="Add Comment" class="form-control" type="text" @change="input=>comment_text = input.target.value" ref="comment"/>
                  </div>
                  <div class="col">
                    <button class="btn btn-primary" @click="submitComment()">Submit</button>
                  </div>
                </div>
              <div class="p-3 card-body">
              <ul class="list-group px-2">
                <li class="px-0 mb-2 border-0 list-group-item"
                v-for="comment of postComments" :key="comment.id"
                >
                <h6 class="row mb-0 text-sm">{{getCommenterName(comment) }}  {{ formatDate(comment.date_created) }}</h6>
                <p class="row mb-0 text-xs">{{ comment.comment_text}}</p>               
                </li>
              </ul>
              </div>
    
          </div>
          <div class="modal-footer">
            <button  @click="openCloseFun()" class="btn btn-primary" >Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="showAttachment" class="modal fade show" 
    tabindex="-1" aria-labelledby="exampleModalLabel1" aria-modal="true" role="dialog" 
    style="display:block">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl ">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h5 class="modal-title">
              Additional Attachment
            </h5>
          </div>
          <div class="modal-body">
            <web-viewer
              :initialDoc="getAdditionalDocPath()"
              :username="currentUser().username"
            />
          </div>
          <div class="modal-footer">
            <button  @click="showAttachment=false" class="btn btn-primary" >Close</button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container border-bottom">
        <div class="col-lg-12 row justify-space-between py-2 container border-bottom">
          <div class="col-lg-8 me-auto">
            <h3  class="lead text-dark pt-1 bold mb-1">
              {{ product.title }}
            </h3>
          </div>
          <div class="col-lg-2">
            <!-- isInstructionCircular -->
            <a
            v-if="isInstructionCircular()"
              class="btn w-100"
              :class="getCircularStatus() ? 'bg-gradient-success' : 'bg-gradient-danger'"
              @click="acceptInstructionCircular()"
            >{{ getStatusText() }}</a
            >
          </div>
          <div class="col-lg-2">
            <a
            v-if="!getReadStatus()"
              class="btn bg-gradient-success w-100"
              href="#"
              @click="goBack"
            >Back</a
            >
            <a
            v-if="getReadStatus()"
              class="btn bg-gradient-success w-100"
              href="#"
            >Read</a
            >
          </div>
          <div class="row">
            {{ product.post_content || "" }}
          </div>
        </div>
      <div>
<!--        {{ blobContent.toString() }}-->
        <web-viewer
          v-if="product.file"
          :initialDoc="getUrlPath()"
          :docId="product.pk"
          :data="blobContent"
          :username="currentUser().username"
          :extension="blobMimeExt"
        />
        <div  hidden="hidden"  class="col-lg-12 row justify-space-between py-2 container border-bottom">
            <div class="col-lg-8 me-auto">
              <p class="col">Showing Additional Attachment</p>
            </div>
          <div class="col-lg-2">
            <!-- isInstructionCircular -->
            <a
            class="btn mx-2"
            :class="'bg-gradient-danger'"
            @click="this.showAttachment = !this.showAttachment"
              >Close</a
              >
          </div>
        </div>

      </div>

    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
// import SoftInput from "@/components/SoftInput.vue";
import {formatDate} from "../utils/index"
import  SoftAlert  from "@/components/SoftAlert.vue"
import WebViewer from "./components/WebViewer.vue";
import store from "@/store";
export default {
  name: "Read",
  components:{
    SoftAlert,
    WebViewer
  },
    created() {
     this.product =  this.$store.state.product
    console.log({ "home":this.product })
     this.getDocumentBlob(this.product.pk);
   },

  methods: {
    ...mapGetters(["getBaseUrl","currentUser"]),
    ...mapActions([]),
    formatDate,
    getUrlPath(){
      const str = this.getBaseUrl()
      if(str.charAt(str. length - 1)==="/"){
        return str.slice(0, -1) + this.post.file 
      }
      return  this.getBaseUrl()+ this.post.file 
    },
      base64ToBlob(base64,mimeType) {
          const binaryString = window.atob(base64);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
          }

      return new Blob([bytes], { type: mimeType });
    },
     async getDocumentBlob (post_id){
      const accessToken = this.$store.getters.currentUser.access
      const res = await axios.get(`/intranet/products/fetch_file_blob/?product_id=${post_id}`,{ headers:{
              Authorization:`Bearer ${accessToken}`
      } });
      const {data} = res
      const mimeType = data.mimeType
      const blobRes =this.base64ToBlob(data.documentBase,mimeType);
      this.blobContent = blobRes;
      this.blobMimeExt = data.extension;
      },   
      async getDocumentBlobAdditionalAttach (attachment_id){
      const accessToken = this.$store.getters.currentUser.access
      const res = await axios.get(`/intranet/products/fetch_file_blob_attachment/?attachment_id=${attachment_id}`,{headers:{
              Authorization:`Bearer ${accessToken}`
      } });
      const {data} = res
      const mimeType = data.mimeType
      const blobRes = this.base64ToBlob(data.documentBase,mimeType);
      this.blobContentAdditionalAttach = blobRes;
      this.blobContentAdditionalAttachExt = data.extension
      },
     async getPost(post_id) {
       const access = this.currentUser().access
        axios.get(`intranet/posts/get_post/?id=${post_id}`,
         {
           headers: {
             Authorization: `Bearer ${access}`
           }
         }).then(response => {
          console.log({ "response.data": response.data });
          store.state.currentPost = response.data
          console.log({ "saved.data": store.state.currentPost });
          this.post = this.getCurrentPost();
          console.log({ "test":this.post });
          this.fetchPostAdditionalAttachments(this.post.pk)
          this.getCommentsForPost(this.post.pk);
          this.getInformationCircularLogs();
          this.getDocumentBlob(this.post.pk);
          store.state.redirect_url = null;
          console.log({ "read": store.state.redirect_url })

       }).catch(error => {
         console.log({ "error": error })
       })
     },
    getAdditionalDocPath(){
      const str = this.getBaseUrl()
      if(str.charAt(str. length - 1)==="/"){
         str.slice(0, -1) + this.additionDocPath
      }
      const res = this.getBaseUrl()+ this.additionDocPath
      return res;
    },
    getReadStatus(){
      //const logs = this.getPostLogs();
      //const user = this.currentUser();
      //const {user_id} = user;
     // const existingLog = logs.find(pLog=>pLog.post_id===this.post.pk && pLog.user_id===user_id);
     // if(existingLog){
       //return true;
     // }
      return false;
    },
    isInstructionCircular(){
      //if(this.post){
        //return this.post.category_id.type === "INSTRUCTION CIRCULAR";
      ///}
      return false
    },
    goBack(){
      this.$router.go(-1);
    },
    getCircularStatus(){
      const post_id = this.post.pk
      const circular_log = this.circularPostLogs.find(lg=>lg.post_id==post_id)
      const {user_id} = this.currentUser();
      if(circular_log && circular_log.user_id === user_id){
        return true
      }
    },
    getStatusText(){
      return this.getCircularStatus() ? "Accepted Circular": "Accept Circular"
    },
    getPathAdditionalDocs(){
      const str = this.getBaseUrl();
      if(str.charAt(str. length - 1)==="/"){
        return str.slice(0, -1) + this.additionDocPath;
      }
      return this.getBaseUrl() + this.additionDocPath;
    },
    getCommenterName(comment){
      if(comment.commenter_id){
        return `${comment.commenter_id.first_name} ${comment.commenter_id.last_name}`
      }
      return "Anonymous"
    },
    openCloseFun(){
      this.modalOpen = !this.modalOpen;
    },
    openAdditionalDocModal(itemPath,id){
      this.additionDocPath = itemPath;
      this.showAttachment = !this.showAttachment;
      this.getDocumentBlobAdditionalAttach(id)
    },
    async getCommentsForPost(post_id){
      try{
        const access = this.currentUser().access
        const response = await axios.get(`intranet/posts/get_post_comments/?post_id=${post_id}`,
        {
          headers:{
              Authorization:`Bearer ${access}`
            }
        });
        if(response.status===200){
          const comments = response.data
          this.postComments = comments
        }
      }catch(error){
        console.log(error)
      }
    },
    async acceptInstructionCircular(){
      try{
        const user = this.currentUser();
        const {user_id} = user; 
        const payload ={
          post_id:this.post.pk,
          user_id
        };
        const access = this.currentUser().access
        const response = await axios.post(`intranet/posts/add_infocircular_log/`,payload,{
          headers:{
              Authorization:`Bearer ${access}`
            }
        });
        if(response.status === 201){
          console.log({response});
        }
      }catch(error){
        console.log(error);
      }
    },
    async getInformationCircularLogs(){
      try{
        const access = this.currentUser().access
        const response = await axios.get(`intranet/posts/fetch_infocircular_logs/`,{
          headers:{
              Authorization:`Bearer ${access}`
            }
        });
        if(response.status === 200){
          const circularPostLogs = response.data;
          this.circularPostLogs = circularPostLogs;
        }
      }catch(error){
        console.log(error)
      }
    },
    async submitComment(){
      if(this.comment_text==""){
        return;
      }
      try{
        const user = this.currentUser();
        const {user_id} = user; 
        const payload ={
          "comment_text":this.comment_text,
          "post_id":this.post.pk,
          "commenter_id":user_id
        };
        const access = this.currentUser().access;
        const response = await axios.post("intranet/posts/add_post_comment/",payload,{
          headers:{
              Authorization:`Bearer ${access}`
            }
        });
        if(response.status==201){
          this.alert={
            message:"Saved Comment Successfully",
            color:"success",
            showAlert:true
          } 
          this.comment_text = "";
          this.$refs["comment"].value = "";
          await this.getCommentsForPost(this.post.pk);
          setTimeout(()=>{
            this.alert = {
              message:"",
              color:"success",
              showAlert:false
            }
          },2000)
        }
      }catch(error){
        console.log(error);
      }
    },
    async fetchPostAdditionalAttachments(post_id){
      try{

        const access = this.currentUser().access;
        const response = await axios.get("intranet/posts/get_more_attachments/?post_id="+post_id,{
          headers:{
              Authorization:`Bearer ${access}`
            }
        });
        if(response.status===200){
          const responseData = response.data
          if(typeof response === "object" && Array.isArray(response.data)){
            const attachments = responseData
            this.postAttachments = attachments
          }
        }
      }catch(error){
        console.log(error)
      }
     }
  },
  data:()=>{
    return {
      post:{},
      product:{},
      modalOpen:false,
      circularPostLogs:[],
      postAttachments:[],
      comment_text:"",
      postComments:[],
      blobContent:null,
      blobMimeExt:null,
      blobContentAdditionalAttach:null,
      blobContentAdditionalAttachExt:null,
      showAttachment:false,
      alert:{
        message:"",
        color:"success",
        showAlert:false
      }
    }
  }
  
};
</script>
