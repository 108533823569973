<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <i class="py-2 fa fa-commenting"> </i>
    </a>
    <div class="shadow-lg card blur">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Send your feedback</h5>
          <p>Send us your thoughts</p>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />

      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <div>
          <h6 class="mb-0">#YouMatterMost</h6>
        </div>
        <div class="mt-3">
        <select class="form-control minimal" name="contacts" id="contacts" :v-model="category" @change="setSelectedCategory($event)">
            <option  value="0">Please Select Category</option>
            <option v-for="feedBackOption in getFeedBackCategories()" :value="feedBackOption" :key="feedBackOption">{{ feedBackOption}}</option>
          </select>
        </div>
        <!-- Sidenav Type -->
        <div class="mt-3">
          <textarea rows="10" v-model="feedbackContent" type="text" class="form-control" placeholder="Type your feedback here" ref="feedback"></textarea>
        </div>
        <p class="mt-2 text-sm d-xl-none d-block">
          You can change the sidenav type just on desktop view.
        </p>
        <!-- Navbar Fixed -->
        <div class="mt-3" v-if="false">
          <h6 class="mb-0">Navbar Fixed</h6>
        </div>
        <div v-if="false" class="form-check form-switch ps-0">
          <input
            class="mt-1 form-check-input"
            :class="this.$store.state.isRTL ? 'float-end  me-auto' : ' ms-auto'"
            type="checkbox"
            id="navbarFixed"
            :checked="this.$store.state.isNavFixed"
            @change="setNavbarFixed"
            v-model="fixedKey"
          />
        </div>
        <hr class="horizontal dark my-sm-4" />
        <button
          class="btn bg-gradient-dark w-100"
          @click="submitFeedBack"
          >Send Feedback</button
        >
        <a v-if="false"
          class="btn btn-outline-dark w-100"
          href="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"
          >View documentation</a
        >
        <div  class="text-center w-100">
          <h6 class="mt-3" v-if="postedFeedBack">Thank you for sharing!</h6>
          <!-- <a
            href="#"
            class="mb-0 btn btn-dark me-2"
            target="_blank"
          >
            <i class="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
          </a>
          <a
            href="#"
            class="mb-0 btn btn-dark me-2"
            target="_blank"
          >
            <i class="fab fa-facebook-square me-1" aria-hidden="true"></i> Share
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      fixedKey: "",
      category:"",
      feedbackContent:"",
      postedFeedBack:false
    };
  },
  created(){
    this.fetchFeedBackCategoriesAction({access:this.currentUser().access})
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed","fetchFeedBackCategories"]),
    ...mapActions(["toggleSidebarColor","saveNewFeedBack","fetchFeedBackCategoriesAction"]),
    ...mapGetters(["getFeedBackCategories","currentUser"]),

    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },
     async submitFeedBack() {
      if(this.category===""){
        alert("Please select appropriate feedback category")
        return;
      }
      await this.saveNewFeedBack(
        {data:{"feedback_content":this.feedbackContent,
        "feedback_category":this.category
      },
        access:this.currentUser().access})
      this.postedFeedBack = true;
      this.category=""
      this.feedbackContent = ""
      this.$refs["feedback"].value = ""
    },
    setSelectedCategory(event){
      this.category = event.target.value
    },
    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
    
    sidenavTypeOnResize() {
      let transparent = document.querySelector("#btn-transparent");
      console.log({transparent})
      let white = document.querySelector("#btn-white");
      if (window.innerWidth < 1200) {
        transparent.classList.add("disabled");
        white.classList.add("disabled");
      } else {
        transparent.classList.remove("disabled");
        white.classList.remove("disabled");
      }
    },
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    // Deactivate sidenav type buttons on resize and small screens
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
};
</script>
