<template>
    <div id="webviewer" ref="viewer"></div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import WebViewer from "@pdftron/webviewer";
  export default {
    name: "WebViewer",
    props: { initialDoc: { type: String } ,data:{type: Blob},username:{type:String},extension:{type:String}},
    setup(props) {
      const viewer = ref(null);
      onMounted(() => {
        const path = `/webviewer`;
        
        WebViewer({ 
          path, 
          // initialDoc: props.initialDoc,  
          disabledElements: [
              'viewControlsButton',
              'viewControlsOverlay'
            ],
            enableAnnotations: false,
          licenseKey:'FBC Holdings Limited:PWS:FBC::B+2:CFC7976AA7A922935EF8AFA7530A2C351FDD40AF81F0FF554DE55F26BD', //'FBC Holdings Limited (fbc.co.zw):PWS:FBC Holdings Limited::B+2:CD03DA8DDD61815C33A681734AA1DD04DD5EB8FFC5B95B2CB61FDD40AF81F0FF554DE55F26BD',// process.env.VUE_ENV_PDFTOKEN  // sign up to get a free trial key at https://dev.apryse.com
        }, viewer.value).then(
          (instance) => {
            // remove left panel and left panel button from the DOM
            const options = {extension:props.extension,}
            instance.UI.loadDocument(
              props.data,options);
            // instance.UI.disableElements([ 'menuButton','header']);
            instance.UI.setZoomLevel('125%');
            // const { documentViewer, annotationManager, Annotations } =
            //   instance.Core;
            // documentViewer.addEventListener("documentLoaded", () => {
            //   const rectangleAnnot = new Annotations.RectangleAnnotation({
            //     PageNumber: 1,
            //     // values are in page coordinates with (0, 0) in the top left
            //     X: 100,
            //     Y: 150,
            //     Width: 200,
            //     Height: 50,
            //     Author: annotationManager.getCurrentUser(),
            //   });
            //   annotationManager.addAnnotation(rectangleAnnot);
            //   annotationManager.redrawAnnotation(rectangleAnnot);
            // });
            const { documentViewer } = instance.Core;

            documentViewer.setWatermark({
              // // Draw diagonal watermark in middle of the document
              // diagonal: {
              //   fontSize: 25, // or even smaller size
              //   fontFamily: 'sans-serif',
              //   color: 'black',
              //   opacity: 50, // from 0 to 100
              //   text:props.username
              // },
              custom: (ctx, _pageNumber, _pageWidth, _pageHeight) => {
            // the pageNumber is also passed in so you could have
            // a different watermark for each page
             console.log({ _pageNumber, _pageWidth, _pageHeight});
             for(let idx=0;idx< 17 ;idx++){
                      const test=props.username;
                      ctx.fillStyle = '#77879D';
                      ctx.font = '8pt Arial';
                      ctx.globalAlpha = 0.4;
                      ctx.fillText(
                        test,
                        50,
                        50+idx*50
                      ); 
                      const calc = 50+test.length+100
                      ctx.fillText(
                        test,
                        calc,
                        50+idx*50,
                      );
                      ctx.fillText(
                        test,
                        calc+test.length+100,
                        50+idx*50
                      );
                      const calc2 = calc+test.length+100
                      ctx.fillText(
                        test,
                        calc2+test.length+100,
                        50+idx*50
                      );
                      ctx.fillText(
                        test,
                        calc2+test.length+100+test.length+100,
                        50+idx*50
                      );
                  }
            },
            });
          }
        );
      });
      return {
        viewer,
      };
    },
  };
  </script>
  
  <style>
  #webviewer {
    height: 100vh;
  }
  </style>
  