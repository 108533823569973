<template>
    <svg  height="80px" width="80px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xml:space="preserve">
<g fill="#18408B">
	<g fill="#18408B">
		<path class="color-background" d="M400.696,66.783H111.304c-9.22,0-16.696,7.475-16.696,16.696v16.696h272.696c9.22,0,16.696,7.475,16.696,16.696v328.348
			h16.696c9.22,0,16.696-7.475,16.696-16.696V83.478C417.391,74.258,409.916,66.783,400.696,66.783z"/>
	</g>
</g>
<g fill="#18408B">
	<g fill="#18408B">
		<path class="color-background" d="M467.478,0H178.087c-9.22,0-16.696,7.475-16.696,16.696v16.696h272.696c9.22,0,16.696,7.475,16.696,16.696v328.348h16.696
			c9.22,0,16.696-7.475,16.696-16.696V16.696C484.174,7.475,476.699,0,467.478,0z"/>
	</g>
</g>
<g fill="#18408B">
	<g fill="#18408B">
		<path class="color-background" d="M333.913,133.565H44.522c-9.217,0-16.696,7.479-16.696,16.696v345.043c0,9.217,7.479,16.696,16.696,16.696h289.391
			c9.217,0,16.696-7.479,16.696-16.696V150.261C350.609,141.044,343.13,133.565,333.913,133.565z M267.13,445.217h-77.913
			c-9.217,0-16.696-7.479-16.696-16.696s7.479-16.696,16.696-16.696h77.913c9.217,0,16.696,7.479,16.696,16.696
			S276.348,445.217,267.13,445.217z M267.13,378.435H111.304c-9.217,0-16.696-7.479-16.696-16.696s7.479-16.696,16.696-16.696
			H267.13c9.217,0,16.696,7.479,16.696,16.696S276.348,378.435,267.13,378.435z M94.609,294.957c0-9.217,7.479-16.696,16.696-16.696
			h47.293c9.217,0,16.696,7.479,16.696,16.696c0,9.217-7.479,16.696-16.696,16.696h-47.293
			C102.087,311.652,94.609,304.174,94.609,294.957z M267.13,311.652h-41.75c-9.217,0-16.696-7.479-16.696-16.696
			c0-9.217,7.479-16.696,16.696-16.696h41.75c9.217,0,16.696,7.479,16.696,16.696C283.826,304.174,276.348,311.652,267.13,311.652z
			 M267.13,244.87H111.304c-9.217,0-16.696-7.479-16.696-16.696c0-9.217,7.479-16.696,16.696-16.696H267.13
			c9.217,0,16.696,7.479,16.696,16.696C283.826,237.391,276.348,244.87,267.13,244.87z"/>
	</g>
</g>
</svg>
</template>