<template>
    <div class="container-fluid">
        <div v-if="modalOpen" class="modal fade show"
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" 
        style="display:block"
       >
      <div v-if="isFbcTvVideoReports" class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header text-center">
                <h5 class="modal-title">
                    Video Stats
                </h5>
                <p class="pt-2">{{ reportDataCurrentVideo.length }} out of {{ userCount }} users watched this video.</p>
            </div>
          <div class="modal-body">
            <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Firstname
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Lastname
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Video Title
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Video Description
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Viewed
                        </th>
                        </tr>
                    </thead>
                    <tbody v-for="item of reportDataCurrentVideo" :key="item.pk">
                        <tr>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                    {{ item.user_id.first_name }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                    {{ item.user_id.last_name }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ item.video_id.title }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ item.video_id.description }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ formatDate(item.date_created)}}
                                </p>
                                </div>
                                 </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
          </div>
          <div class="modal-footer">
                  <button  @click="openCloseFun()" class="btn btn-primary" >Close</button>
              </div>
        </div>
        </div>
      <div v-if="!isFbcTvVideoReports" class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h5 class="modal-title">
                    Article Stats
                </h5>
            </div>
            <div class="modal-body">
                <h5 class="text-center">
                    Users Not Seen Post
                </h5>
                <!-- <p>
                   {{ reportDataCurrentPost.usersNotSeen }}
                </p> -->
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Firstname
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Lastname
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Post Title
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Post Type
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Posted
                        </th>
                        </tr>
                    </thead>
                    <tbody v-for="item of reportDataCurrentPost.usersNotSeen" :key="item.pk">
                        <tr>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                    {{ item.first_name }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                    {{ item.last_name }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ reportDataCurrentPost.postTitle }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ reportDataCurrentPost.postType }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ formatDate(reportDataCurrentPost.datePosted)}}
                                </p>
                                </div>
                                 </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <h5 class="text-center">
                    Users  Seen Post
                </h5>
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Firstname
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Lastname
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Post Title
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Post Type
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Posted
                        </th>
                        </tr>
                    </thead>
                    <tbody v-for="item of reportDataCurrentPost.usersSeenPost" :key="item.pk">
                        <tr>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                    {{ item.user_id.first_name }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                    {{ item.user_id.last_name }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ item.post_id.title }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ item.post_id.type }}
                                </p>
                                </div>
                                 </div>
                            </td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                <p class="text-xs text-secondary mb-0">
                                   {{ formatDate(item.post_id.date_created)}}
                                </p>
                                </div>
                                 </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" @click="downloadExcelReport(reportDataCurrentPost)">Export Report</button>
                <button  @click="openCloseFun()" class="btn btn-primary" >Close</button>
            </div>
        </div>
      </div>
      </div>
      <div
        class="mt-3 page-header min-height-200 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-primary opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n8">
        <div class="row gx-4">
          <div class="col-auto my-auto">
              <archives-icon></archives-icon>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Statistics</h5>
              <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
            <div class="nav-wrapper position-relative end-0">
              <div class="row">
                <div class="col" >
                  <p>Start Date</p>
                  <soft-input placeholder="Select Date" type="date" @input="event=>startDate=event.target.value"/>
                </div>
                <div class="col">
                  <p>End Date</p>
                  <soft-input  placeholder="Select Date" type="date" @input="event=>endDate=event.target.value"/>            
                </div>
                <div class="col form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="isFbcTvVideoReports">
                  <label class="form-check-label" for="flexSwitchCheckChecked">Show FBCTV Reports</label>
                </div>
              </div>
              <div class="row">
              <div v-if="!isFbcTvVideoReports" class="col">
                  <p class="text-sm font-weight-bold">Select Post Category</p>
                  <select class="form-control minimal" name="contacts" id="contacts" @change="handleSelect($event)">
                    <option  value="0">Please Select</option>
                    <option v-for="category in this.getCategoryOptions()" :value="category.id" :key="category.id">{{ category.name }}</option>
                  </select>
                </div>
              <div class="col mt-auto pt-4">
                <soft-button
                      class=""
                      variant="gradient"
                      color="primary"
                      full-width
                      @click="filter"
                      >Filter     
                </soft-button>
              </div>
            </div>
              <!-- <ul
                class="p-1 bg-transparent nav nav-pills nav-fill"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link active"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="true"
                  >
                    <span class="ms-1">Read</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="ms-1">Unread</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="ms-1">Latest</span>
                  </a>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="p-3 card-body">
              <div  v-if="!isFbcTvVideoReports" class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                      <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Title
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Post
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Posted
                        </th>
                        <th class="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody v-for="item of archivedPosts" :key="item.id">
                        <tr
                         style="cursor: pointer;"
                         @click="goTo(item.pk,item)"
                        >
                        <td>
                          <div class="d-flex px-2 py-1">
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="mb-0 text-sm">{{ item.title  }}</h6>
                              <p class="text-xs text-secondary mb-0">
                                {{ item.description }}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <soft-badge  color="success" variant="gradient" size="sm"
                            @click="goTo(item.pk,item)"
                            >Load Report
                          </soft-badge
                          >
                        </td>
                        <td  class="align-middle text-center">
                          <span class="text-secondary text-xs font-weight-bold">
                            {{ item.type }}
                          </span>
                        </td>
                        <td class="align-middle text-center">
                          <span class="text-secondary text-xs font-weight-bold"
                            >{{ formatDate(item.date_created) }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>

              <div v-if="isFbcTvVideoReports" class="table-responsive p-0" style="height: 500px;">
                <table class="table align-items-center mb-0">
                  <thead>
                      <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Title
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Description
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Action
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Posted
                        </th>
                        <th class="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody v-for="item of videos" :key="item.id">
                      <tr>
                      <td>
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ item.title  }}</h6>

                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-xs text-secondary mb-0">
                              {{ item.description }}
                            </p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <soft-badge  color="success" variant="gradient" size="sm"
                         @click="getVideoStats(item.id)"
                          ><i class="fa-video px-2"></i>Load Report</soft-badge
                        >
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ formatDate(item.date_created) }}</span
                        >
                      </td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import sophie from "@/assets/img/kal-visuals-square.jpg";
  import marie from "@/assets/img/marie.jpg";
  import ivana from "@/assets/img/ivana-square.jpg";
  import ArchivesIcon from "../components/Icon/ArchivesIcon.vue";
  import peterson from "@/assets/img/team-4.jpg";
  import nick from "@/assets/img/team-3.jpg";
  import img1 from "@/assets/img/home-decor-1.jpg";
  import img2 from "@/assets/img/home-decor-2.jpg";
  import img3 from "@/assets/img/home-decor-3.jpg";
  import perfomance from "@/assets/img/bonus.jpeg";
  import coins from "@/assets/img/coins.jpeg";
  import prices from "@/assets/img/prices.png";
  import job from "@/assets/img/job.png";
  import team1 from "@/assets/img/team-1.jpg";
  import team2 from "@/assets/img/team-2.jpg";
  import team3 from "@/assets/img/team-3.jpg";
  import team4 from "@/assets/img/team-4.jpg";
  import {
    faFacebook,
    faTwitter,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";

import SoftBadge from "@/components/SoftBadge.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapMutations,mapActions } from "vuex";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton  from "@/components/SoftButton.vue";
import {formatDate} from "../utils/index"
import axios from "axios"
import moment  from "moment";
import * as XLSX from 'xlsx';



  export default {
    name: "Statistics",
    components: {
      SoftBadge,
      SoftInput,
      SoftButton,
      ArchivesIcon
    },
    data() {
      return {
        showMenu: false,
        sophie,
        marie,
        ivana,
        peterson,
        nick,
        img1,
        team1,
        team2,
        team3,
        team4,
        img2,
        img3,
        perfomance,
        prices,
        job,
        coins,
        faFacebook,
        faTwitter,
        faInstagram,
        archivedPosts:[],
        startDate:"",
        endDate:"",
        category_id:0,
        modalOpen:false,
        isFbcTvVideoReports:false,
        videos:[],
        userCount:0,
        reportDataCurrentPost:{
            usersNotSeen:null,
            usersSeenPost:null,
            postTitle:null,
            datePosted:null,
            postType:null,
        },
        reportDataCurrentVideo:{

        }
      };
    },
    methods:{
      ...mapGetters(["currentPosts","getPostLogs","currentUser","getPostCategories"]),
      ...mapMutations(["fetchPostLogs","fetchPosts","fetchCategories"]),
      ...mapActions(["setNewPost","saveNewPostLog","fetchCategoriesAction"]),
      formatDate,
      getPostLogStatus(post_id){
        const currentUser = this.currentUser();
        const {user_id} = currentUser
        const postLogs = this.getPostLogs()
        const existingLog = postLogs.find(pLog=>pLog.post_id.pk===post_id && pLog.user_id.pk===user_id)
        if(existingLog){
          return true
        }
        return false
      },
      getCategoryOptions(){
      return [ {'id': 0,'description': "N/A",'name': 'ALL'},...this.getPostCategories()]
    },
    async getUserCount(){
      try{
        const user = this.currentUser();
        const {access} = user; 
        const response = await axios.get(
          "intranet/posts/get_user_count/",
          { headers:{
              Authorization:`Bearer ${access}`
            }}
        )
        if(response.status==200){
          this.userCount = response.data["userCount"]
        }
      }catch(error){
        console.log(error)
      }
    },
    async fetchVideos(){
      try{
        const accessToken = this.currentUser().access
        const response = await axios.get(`intranet/posts/get_videos_range/?start_date=${moment(this.startDate).format('MM-DD-YYYY')}&end_date=${moment(this.endDate).format('MM-DD-YYYY')}`,
        {
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
      }
        )
        if(response.status==200){
          const videos = response.data
          this.videos = videos
        }
      }catch(error){
        console.log(error)
      }
    },
      filter(){
        if(this.endDate.length > 0 && this.startDate.length > 0){
          this.fetchArchivedPosts()
          this.fetchVideos()
        }
      },
      handleSelect(event){
        this.category_id = event.target.value
      },
      openCloseFun(){
      this.modalOpen = !this.modalOpen;
       },
      downloadExcelReport(reportObject){
          const formattedResponseNotSeen = reportObject.usersNotSeen && reportObject.usersNotSeen.map(item=>{
            return {
              Firstname:item.first_name,
              Lastname: item.last_name,
              PostTitle:reportObject.postTitle,
              PostType:reportObject.postType,
              DatePosted:reportObject.datePosted
            }
          })
          const formattedResponseSeen = reportObject.usersSeenPost && reportObject.usersSeenPost.map(item=>{
            return {
              Firstname:item.user_id.first_name,
              Lastname: item.user_id.last_name,
              PostTitle:item.post_id.title,
              PostType:item.post_id.postType,
              DatePosted:item.post_id.datePosted
            }
          })
          const not_seen = XLSX.utils.json_to_sheet(formattedResponseNotSeen || [])
          const seen = XLSX.utils.json_to_sheet(formattedResponseSeen || [])
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, not_seen, 'User Not Viewed Post',true)
          XLSX.utils.book_append_sheet(wb, seen, 'Users Viewed Post',true)
          XLSX.writeFile(wb,'demo.xlsx')
       },
      async getUserNotSeenPost(post_id){
        try{
            const accessToken = this.currentUser().access
            const response = await axios.get(
                `intranet/posts/fetch_user_not_seen_post/?post_id=${post_id}`, {
                 headers:{
                Authorization:`Bearer ${accessToken}`
                 }
                }
            )
            if(response.status == 200){
                const usersNotSeen = response.data
                console.log({usersNotSeen})
                this.reportDataCurrentPost = {
                    usersNotSeen
                }
            }
        }catch(err){
            console.log(err)
        }
      },
      
      // get_archived_given range
      async fetchArchivedPosts(){
        try{
          const accessToken = this.currentUser().access
          const response = await axios.get(
            `intranet/posts/get_archives_posts/?start_date=${moment(this.startDate).format('MM-DD-YYYY')}&end_date=${moment(this.endDate).format('MM-DD-YYYY')}`,
            {
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
            }
          )
          if(response.status == 200){
            const archivedPosts = response.data
            if(+this.category_id!==0 ){
              this.archivedPosts = archivedPosts.filter(post=>post.category_id.id===+this.category_id)
              return
            }
            this.archivedPosts = archivedPosts
          }
        }catch(error){
          console.log(error)
        }
       },
       async goTo(post_id,obj) {
        Promise.allSettled([
                this.getUsersSeenPost(post_id),
                this.getUserNotSeenPost(post_id)
        ]).then(()=>{
            
            if(this.reportDataCurrentPost.usersNotSeen){
                this.reportDataCurrentPost.datePosted = obj.date_updated
                this.reportDataCurrentPost.postTitle = obj.title
                this.reportDataCurrentPost.postType = obj.type
            }
            this.modalOpen = true

        })
    },
    async getVideoStats(video_id){
      try{
      const accessToken = this.currentUser().access
      const response = await axios.get(
        `intranet/posts/get_video_logs/?video_id=${video_id}`,{
        headers: { Authorization:`Bearer ${accessToken}` }
        }
      )
      if(response.status==200){
        this.reportDataCurrentVideo = response.data
        this.modalOpen = true
      }
    } catch(error){
      console.log(error)
    }
    },
    async getUsersSeenPost(post_id){
      try{
          const accessToken = this.currentUser().access
          const response = await axios.get(
              `intranet/posts/fetch_logs_for_post/?post_id=${post_id}`, {
                headers:{
              Authorization:`Bearer ${accessToken}`
                }
              }
          )
          if(response.status == 200){
              const userSeenPostAggregate = response.data
              console.log({userSeenPostAggregate})
              this.reportDataCurrentPost = {
                  ...this.reportDataCurrentPost,
                  usersSeenPost:userSeenPostAggregate
              }
          }
      }catch(err){
          console.log(err)
      }
    },
    },
    created(){
      this.fetchCategoriesAction({access:this.currentUser().access})
      this.getUserCount()
      const posts = this.currentPosts()
      this.posts=posts
    },
    mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
  };
  </script>
  
