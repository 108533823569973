import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import ReadPost from "@/views/Read.vue";
import Circulars from "@/views/Circulars.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Jump from "@/views/Jump.vue";
import Contacts from "@/views/Contacts.vue";
import Statistics from "@/views/Statistics.vue"
import Products from "@/views/Products.vue";
import Policies from "@/views/Policies.vue";
import Holdings from "@/views/Holdings.vue";
import Archives from "@/views/Archives.vue"
import TV from "@/views/TV.vue";
import store from "../store/index"
import ReadProduct from "@/views/ReadProduct.vue";
import ReadPolicy from "@/views/ReadPolicy.vue";

import Assistance from "@/views/Assistance.vue";
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false || !store.state.loggedInUser.access ){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/circulars",
    name: "Circulars",
    component: Circulars,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
   {
     path: "/policies",
    name: "Policies",
    component: Policies,
   beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
      next("/sign-in");
    }else{
        next();
       }
     }
  },
  {
    path: "/assistance",
    name: "Assistance",
    component: Assistance,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {

    path: '/article/:id',
    redirect: to => {
      console.log({"redirect": to.params.id })
      store.state.redirect_url = to.params.id;
      return { path: '/read', query: { q: to.params.id } }
    },
  },
  {
    path: "/readProduct",
    name: "ReadProduct",
    component: ReadProduct,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },

  {
    path: "/holdings",
    name: "Holdings",
    component: Holdings,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/tv",
    name: "Pulse TV",
    component: TV,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/read",
    name: "Post",
    component: ReadPost,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/readPolicy",
    name: "ReadPolicy",
    component: ReadPolicy,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/archive",
    name: "Archives",
    component: Archives,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/jump",
    name: "Jump",
    component: Jump,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===true){
        next("/dashboard");
      }else{
        next()
      }
    }
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===false){
        next("/sign-in");
      }else{
        if(store.state.loggedInUser.groups.includes("View_Reports")){
          next()
        }else{
          next("/dashboard");
        }
      }
    }
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    beforeEnter:(to,from,next)=>{
       if(store.state.isAuthenticated===false){
         next("/sign-in");
       }else{
         next();
       }
     }
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
    beforeEnter:(to,from,next)=>{
      if(store.state.isAuthenticated===true){
        next("/sign-in");
      }else{
        next();
      }
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
