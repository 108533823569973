<template>
  <div class="container-fluid">
    <!-- Modal -->
      <soft-alert v-if="alert.showAlert" style="z-index: 9999;" :color="alert.color">
        {{ alert.message }}
      </soft-alert>
    <div v-if="modalOpen" class="modal fade show" 
    tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" 
    style="display:block">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h5 class="modal-title">
              {{ selectedVideo.title }}
            </h5>
          </div>
          <div class="modal-body">
                <videoplayer-component
                    class="text-center rounded"
                    :src="getVideoSource(selectedVideo.video)"
                    :muted="false"
                    :autoplay="false"
                    :controls="true"
                    :loop="false"
                    :poster="getImageUrl(selectedVideo.thumbnail)"
                    @play="onPlayerPlay"
                    @pause="onPlayerPause"
                    @ended="onPlayerEnded"
                    @loadeddata="onPlayerLoadeddata"
                    @waiting="onPlayerWaiting"
                    @playing="onPlayerPlaying"
                    @timeupdate="onPlayerTimeupdate"
                    @canplay="onPlayerCanplay"
                    @canplaythrough="onPlayerCanplaythrough"
                    @statechanged="playerStateChanged"
                  >
                </videoplayer-component>
                <h5 class="text-center text-sm mb-4">{{ selectedVideo.description }}</h5>
                <div class="row">
                  <div class="col">
                    <input class="form-control" placeholder="Add Comment" type="text" @change="input=>comment_text = input.target.value" ref="comment" />
                  </div>
                  <div class="col">
                    <button class="btn btn-primary" @click="submitComment()">Submit</button>
                  </div>
                </div>
                <h6 class="mb-0">Comments <span>{{ videoComments.length }}<i class="cursor-pointer  px-2  fas fa-comments fixed-plugin-button-nav"></i></span></h6>
                    <div class="p-3 card-body">
                    <ul class="list-group px-2">
                      <li
                      v-for="comment of videoComments" :key="comment.id"
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                      >
                        <div
                        class="d-flex align-items-start flex-column justify-content-center"
                        >
                        <h6 class="mb-0 text-sm"> {{ getCommenterName(comment) }}</h6>
                        <p class="mb-0 text-xs">{{ comment.comment_text}}</p>
                      </div>
                      </li>
                     </ul>
                  </div>
          </div>
          <div class="modal-footer">
            <button  @click="openCloseFun()" class="btn btn-primary" >Close</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-3 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-primary opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="@/assets/img/pulseTV.png"
              alt="profile_image"
              class="w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <!-- <h5 class="mb-1">FBC TV</h5> -->
            <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <!-- <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <span class="ms-1">App</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="ms-1">Messages</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="ms-1">Settings</span>
                </a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="p-3 card-body">
      <div>Recent Uploaded Videos</div>
      <div class="row">
        <div class="mb-4 col-xl-3 col-md-6 mb-xl-0 mt-4"
        v-for="item of videos" :key="item.id"
        >
    <div class="card card-blog card-plain card-post"
    >
      <div class="position-relative">
        <a class="shadow-xl d-block border-radius-xl">
          <img
            :src="getImageUrl(item.thumbnail)"
            alt="img-blur-shadow"
            height="200"
            @click="watchVideo(item)"
            class="shadow img-fluid2 border-radius-xl"
          />
         
        </a>
      </div>
      <div class="px-1 pb-0 card-body">
        <p class="mb-2 text-sm text-gradient text-dark">{{ label }}</p>
        <a href="javascript:;">
          <h5>{{ item.title }}</h5>
        </a>
        <p class="mb-4 text-sm">
          {{ item.description }}
        </p>
        <div class="d-flex align-items-center justify-content-between"
       
        >
          <!-- <soft-badge  color="success" variant="gradient" size="sm"
              @click="watchVideo(item)"
                          ><i class="fa fa-video px-2"></i>Watch</soft-badge
                        > -->
        <soft-button color="success" size="sm" variant="outline"
           @click="watchVideo(item)"
            ><i class="fa fa-video px-2"></i> Watch</soft-button
          >
          <div class="mt-2 avatar-group">
            <!-- <a
              v-for="({ image: authorImage, name }, index) of authors"
              :key="index"
              href="javascript:;"
              class="avatar avatar-xs rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="name"
            >
              <img :alt="authorImage" :src="authorImage" />
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
      </div>
              <div v-if="false" class="table-responsive p-0" style="height: 500px;">
                <table class="table align-items-center mb-0">
                  <thead>
                      <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Title
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Description
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Action
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Posted
                        </th>
                        <th class="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody v-for="item of videos" :key="item.id">
                      <tr>
                      <td>
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ item.title  }}</h6>

                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-xs text-secondary mb-0">
                              {{ item.description }}
                            </p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <soft-badge  color="success" variant="gradient" size="sm"
                          @click="watchVideo(item)"
                          ><i class="fa-video px-2"></i>Watch</soft-badge
                        >
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ formatDate(item.date_created) }}</span
                        >
                      </td>
                    </tr>
                            </tbody>
                        </table>
              </div>
            </div>
    <div v-if="false" class="mt-3 row">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Platform Settings</h6>
          </div>
          <div class="p-3 card-body">
            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              Account
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault"
                  name="email"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  checked
                  >Email me when someone follows me</soft-switch
                >
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault1"
                  name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  class="ps-0 ms-auto"
                  >Email me when someone answers on my post</soft-switch
                >
              </li>

              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault2"
                  name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  class="ps-0 ms-auto"
                  checked
                  >Email me when someone mentions me</soft-switch
                >
              </li>
            </ul>
            <h6
              class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
            >
              Application
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault3"
                  name="Project Launch"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  >New launches and projects</soft-switch
                >
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault4"
                  name="Product Update"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  checked
                  >Monthly product updates</soft-switch
                >
              </li>
              <li class="px-0 pb-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault5"
                  name="Newsletter"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  >Subscribe to newsletter</soft-switch
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <profile-info-card
          title="Profile Information"
          description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA',
          }"
          :social="[
            {
              link: 'https://www.facebook.com/CreativeTim/',
              icon: faFacebook,
            },
            {
              link: 'https://twitter.com/creativetim',
              icon: faTwitter,
            },
            {
              link: 'https://www.instagram.com/creativetimofficial/',
              icon: faInstagram,
            },
          ]"
          :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
        />
      </div>
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Conversations</h6>
          </div>
          <div class="p-3 card-body">
            <ul class="list-group">
              <li
                class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
              >
                <soft-avatar
                  class="me-3"
                  :img="sophie"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">Sophie B.</h6>
                  <p class="mb-0 text-xs">Hi! I need more information..</p>
                </div>
                <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Reply</a
                >
              </li>
              <li
                class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
              >
                <soft-avatar
                  class="me-3"
                  :img="marie"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">Anne Marie</h6>
                  <p class="mb-0 text-xs">Awesome work, can you..</p>
                </div>
                <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Reply</a
                >
              </li>
              <li
                class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
              >
                <soft-avatar
                  class="me-3"
                  :img="ivana"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">Ivanna</h6>
                  <p class="mb-0 text-xs">About files I can..</p>
                </div>
                <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Reply</a
                >
              </li>
              <li
                class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
              >
                <soft-avatar
                  class="me-3"
                  :img="peterson"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">Peterson</h6>
                  <p class="mb-0 text-xs">Have a great afternoon..</p>
                </div>
                <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Reply</a
                >
              </li>
              <li
                class="px-0 border-0 list-group-item d-flex align-items-center"
              >
                <soft-avatar
                  class="me-3"
                  :img="nick"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">Nick Daniel</h6>
                  <p class="mb-0 text-xs">Hi! I need more information..</p>
                </div>
                <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Reply</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if = "false" class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div v-if="false" class="p-3 pb-0 card-header">
            <h6 class="mb-1">Projects</h6>
            <p class="text-sm">Architects design houses</p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <default-project-card
                title="Performance Bonus Circular"
                :image="perfomance"
                label="Marketing and Public Relations"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Internal Job Advert - Clerk"
                :image="job"
                label="Marketing and Public Relations"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Today's Prices"
                :image="prices"
                label="FBC Securities Research"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Gold Coin Price"
                :image="coins"
                label="FBC Bank Treasury"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Internal Job Advert - Clerk"
                :image="job"
                label="Marketing and Public Relations"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Gold Coin Price"
                :image="coins"
                label="FBC Bank Treasury"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Performance Bonus Circular"
                :image="perfomance"
                label="Marketing and Public Relations"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />

              <default-project-card
                title="Today's Prices"
                :image="prices"
                label="FBC Securities Research"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'primary',
                  label: 'View Post',
                }"
              />


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftInput from "@/components/SoftInput.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import perfomance from "@/assets/img/bonus.jpeg";
import coins from "@/assets/img/coins.jpeg";
import prices from "@/assets/img/prices.png";
import job from "@/assets/img/job.png";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import SoftBadge from "@/components/SoftBadge.vue";
import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import VideoplayerComponent from "./components/VideoplayerComponent.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import {formatDate} from "../utils/index"
import { mapGetters } from "vuex";
import  SoftAlert  from "@/components/SoftAlert.vue"
import SoftButton  from "@/components/SoftButton.vue";
export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    SoftAvatar,
    DefaultProjectCard,
    SoftBadge,
    // SoftInput,
    SoftButton,
    VideoplayerComponent,
    SoftAlert
  },
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      perfomance,
      prices,
      job,
      coins,
      faFacebook,
      faTwitter,
      faInstagram,
      time: 0,
      videos:[],
      modalOpen:false,
      selectedVideo:null,
      comment_text:"",
      videoComments:[],
      randomNames:["Horse","Woodchuck","Bald eagle","Impala","Armadillo","Hyena","Red-tailed Hawk",
    ],
      alert:{
        message:"",
        color:"success",
        showAlert:false
      }
    };
  },
  methods:{
    ...mapGetters(["getBaseUrl","currentUser"]),
    formatDate,
    async fetchVideos(){
      try{
        const accessToken = this.currentUser().access
        const response = await axios.get("intranet/posts/get_videos/",
        {
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
      }
        )
        if(response.status==200){
          const videos = response.data
          this.videos = videos
        }
      }catch(error){
        console.log(error)
      }
    },
    async currentVideoComments(){
      if(this.selectedVideo){
        try{
          const accessToken = this.currentUser().access
          const response = await axios.get(`intranet/posts/fetch_video_comment/?video_id=${this.selectedVideo.id}`,
              {
                headers:{
                  Authorization:`Bearer ${accessToken}`
                }
          })
          if(response.status==200){
            this.videoComments=response.data;
          }
        }catch(error){
          console.log(error);
        }
      }
    },
    async submitComment(){
      if(this.comment_text==""){
        return;
      }
      try{
        const user = this.currentUser();
        const {user_id} = user; 
        const payload ={
          "comment_text":this.comment_text,
          "video_id":this.selectedVideo.id,
          "user_id":user_id
        };
        const accessToken = this.currentUser().access
        const response = await axios.post("intranet/posts/submit_comment/",payload, {
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
        });
        if(response.status==201){
          this.alert={
            message:"Saved Comment Successfully",
            color:"success",
            showAlert:true
          } 
          this.comment_text = "";
          this.$refs["comment"].value = "";
          await this.currentVideoComments();
          setTimeout(()=>{
            this.alert = {
              message:"",
              color:"success",
              showAlert:false
            }
          },2000)
        }
      }catch(error){
        console.log(error);
      }
    },
    openCloseFun(){
      this.modalOpen = !this.modalOpen;
    },
    getVideoSource(sourceUrl){
      const str = this.getBaseUrl()
      if(str.charAt(str. length - 1)==="/"){
        return str.slice(0, -1) + sourceUrl
      }
      return this.getBaseUrl() + sourceUrl;
    },
    getImageUrl(sourceUrl){
      const str = this.getBaseUrl()
      if(str.charAt(str. length - 1)==="/"){
        return str.slice(0, -1) + sourceUrl
      }
      if(!sourceUrl){
        return job
      }
      return this.getBaseUrl() + sourceUrl;
    },
    getCommenterName(comment){
      if(comment.user_id){
        return `${comment.user_id.first_name} ${comment.user_id.last_name}`
      }
    },
    watchVideo(video){
      this.selectedVideo = video;
      if(this.selectedVideo){
        this.currentVideoComments();
        this.openCloseFun();
        this.logVideoWatch(video.id)
      }
    },
    async logVideoWatch(video_id){
      try{
        const user = this.currentUser();
        const {user_id,access} = user; 
        const response = await axios.post(
          "intranet/posts/log_video_watch/",
          {
            "video_id":video_id,
            "user_id":user_id
          },
          { headers:{
              Authorization:`Bearer ${access}`
            }}
        )
        if(response.status==200){
          console.log(response)
        }
      }catch(error){
        console.log(error)
      }
    },
    // TODO: Need to remove these
    onPlayerPlay({ event, player }) {
      console.log(event.type);
      player.setPlaying(true);
    },
    onPlayerPause({ event, player }) {
      console.log(event.type);
      player.setPlaying(false);
    },
    onPlayerEnded({ event, player }) {
      console.log(event.type);
      player.setPlaying(false);
    },
    onPlayerLoadeddata({ event }) {
      console.log(event.type);
    },
    onPlayerWaiting({ event }) {
      console.log(event.type);
    },
    onPlayerPlaying({ event }) {
      console.log(event.type);
    },
    onPlayerTimeupdate({ event }) {
      this.time = event.target.currentTime;
      console.log({ event: event.type, time: event.target.currentTime });
    },
    onPlayerCanplay({ event }) {
      console.log(event.type);
    },
    onPlayerCanplaythrough({ event }) {
      console.log(event.type);
    },
    playerStateChanged({ event }) {
      console.log(event.type);
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.fetchVideos();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>


<style>
.videoplayer {
  width: 500px;
}

.videoplayer-controls {
  display: flex;
  font: 0.8em sans-serif;
  width: 100%;
}

.videoplayer-controls-toggleplay,
.videoplayer-controls-togglemute {
  flex: 1;
}

.videoplayer-controls-time {
  flex: 2;
  text-align: center;
  line-height: 2;
}

.videoplayer-controls-track {
  flex: 5;
  line-height: 2;
}

.card-post {
  padding: 0.5rem;
}
/*class="card card-blog card-plain"*/
.card-post:hover {
  /* change the background color when 
 text-decoration: underline; */
  /*cursor: pointer;*/
  /*font-weight: bold;*/
  /*word-wrap: break-word;*/
  /*background-clip: border-box;*/
  /*border: 1px solid #00000020;*/
  /*border-radius: 0.8rem;*/
  background: #f1f1f1;
  /*color:#0065b5;*/
}
</style>