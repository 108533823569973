<template>
  <div class="mb-4 col-xl-3 col-md-6 mb-xl-0 mt-4 pe-auto" >
    <div class="card card-blog card-plain">
      <div class="position-relative">
        <a class="shadow-xl d-block border-radius-xl"
        
        >
          <img
            :src="image"
            alt="img-blur-shadow"

            @click="goTo()"
            height="200"
            class="shadow img-fluid2 border-radius-xl"
          />
         
        </a>
      </div>
      <div class="px-1 pb-0 card-body">
        <!-- <p class="mb-2 text-sm text-gradient text-dark">{{ label }}</p> -->
        <a href="javascript:;"
        @click="goTo()"
        >
          <h5>{{ title }}</h5>
        </a>
        <p class="mb-4 text-sm">
          {{ description }}
        </p>
        <div class="d-flex align-items-center justify-content-between">
          <button
            type="button"
            class="mb-0 btn btn-sm"
            :class="`btn-outline-${action.color}`"
            @click = goTo()
          >
            {{ action.label }}
          </button>

          <div class="mt-2 avatar-group">
          <!-- {{  postCommentCount().then(val=>val) }} Comments -->
            <!-- <a
              v-for="({ image: authorImage, name }, index) of authors"
              :key="index"
              href="javascript:;"
              class="avatar avatar-xs rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="name"
            >
              <img :alt="authorImage" :src="authorImage" />
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "DefaultProjectCard",
  props: {
    image: {
      type: String,
      default: "",
    },
    post_id:{
      type:Number,
      default:-1,
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Default Project Card",
    },
    description: {
      type: String,
      default: "",
    },
    action: {
      type: Object,
      route: String,
      color: String,
      label: String,
      default: () => {},
    },
    authors: {
      type: Array,
      image: String,
      name: String,
      default: () => [],
    },
  },
  components:{
  },
  methods: {
    ...mapGetters(["getPostLogs","currentUser"]),
    ...mapActions(["setNewPost","saveNewPostLog"]),
    created(){
      console.log("Hello World")
    },
    data:()=>{
      return {
        postLogs:[],
        postComments:[]
      }
    },
    async postCommentCount(){
      try{
      const response = await axios.get(`intranet/posts/get_post_comments/?post_id=${this.post_id}`);
        if(response.status===200){
          const comments = response.data
          return comments.length
        }
      }catch(error){
        return 0
      }
    },
    async goTo() {
      const postLogs  = this.getPostLogs();
      const currentUser = this.currentUser();
      const {user_id}=currentUser
      const existingLog = postLogs.find(pLog=>pLog.post_id.pk===this.post_id && pLog.user_id.pk===user_id)
      if(!existingLog){
        this.saveNewPostLog({
                data:{
                post_id:this.post_id,
                user_id
                },
                access:this.currentUser().access
        })
      } 
      this.setNewPost({id:this.post_id})//,obj:{}});
      this.$router.push(`/read`,{
      })
    },
    async handleViewPost(post_id,user_id){
      const response  =  await axios.post({post_id,user_id})
      return response
    }
  }
};
</script>
