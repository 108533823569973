<template>
    <div class="container-fluid">
      <div
        class="mt-3 page-header min-height-200 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-primary opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n8">
        <div class="row gx-4">
          <div class="col-auto my-auto">
              <archives-icon></archives-icon>
              <!-- <img
                src="@/assets/img/thejump.png"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              /> -->
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Archived Posts</h5>
              <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
            <div class="nav-wrapper position-relative end-0">
              <div class="row">
                <div class="col" >
                  <p>Start Date</p>
                  <soft-input placeholder="Select Date" type="date" @input="event=>startDate=event.target.value"/>
                </div>
                <div class="col">
                  <p>End Date</p>
                  <soft-input  placeholder="Select Date" type="date" @input="event=>endDate=event.target.value"/>            
                </div>
              </div>
              <div class="row">
              <div class="col">
                  <p class="text-sm font-weight-bold">Select Post Category</p>
                  <select class="form-control minimal" name="contacts" id="contacts" @change="handleSelect($event)">
                    <option  value="0">Please Select</option>
                    <option v-for="category in this.getCategoryOptions()" :value="category.id" :key="category.id">{{ category.name }}</option>
                  </select>
                </div>
              <div class="col mt-auto pt-4">
                <soft-button
                      class=""
                      variant="gradient"
                      color="primary"
                      full-width
                      @click="filter"
                      >Filter     
                </soft-button>
              </div>
            </div>
              <!-- <ul
                class="p-1 bg-transparent nav nav-pills nav-fill"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link active"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="true"
                  >
                    <span class="ms-1">Read</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="ms-1">Unread</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="ms-1">Latest</span>
                  </a>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div v-if="false" class="mt-3 row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Platform Settings</h6>
            </div>
            <div class="p-3 card-body">
              <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                Account
              </h6>
              <ul class="list-group">
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault"
                    name="email"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    checked
                    >Email me when someone follows me</soft-switch
                  >
                </li>
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault1"
                    name="Email"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    class="ps-0 ms-auto"
                    >Email me when someone answers on my post</soft-switch
                  >
                </li>
  
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault2"
                    name="Email"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    class="ps-0 ms-auto"
                    checked
                    >Email me when someone mentions me</soft-switch
                  >
                </li>
              </ul>
              <h6
                class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
              >
                Application
              </h6>
              <ul class="list-group">
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault3"
                    name="Project Launch"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    >New launches and projects</soft-switch
                  >
                </li>
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault4"
                    name="Product Update"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    checked
                    >Monthly product updates</soft-switch
                  >
                </li>
                <li class="px-0 pb-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault5"
                    name="Newsletter"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    >Subscribe to newsletter</soft-switch
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
          <profile-info-card
            title="Profile Information"
            description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
            :info="{
              fullName: 'Alec M. Thompson',
              mobile: '(44) 123 1234 123',
              email: 'alecthompson@mail.com',
              location: 'USA',
            }"
            :social="[
              {
                link: 'https://www.facebook.com/CreativeTim/',
                icon: faFacebook,
              },
              {
                link: 'https://twitter.com/creativetim',
                icon: faTwitter,
              },
              {
                link: 'https://www.instagram.com/creativetimofficial/',
                icon: faInstagram,
              },
            ]"
            :action="{
              route: 'javascript:;',
              tooltip: 'Edit Profile',
            }"
          />
        </div>
        <div class="mt-4 col-12 col-xl-4 mt-xl-0">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Conversations</h6>
            </div>
            <div class="p-3 card-body">
              <ul class="list-group">
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="sophie"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Sophie B.</h6>
                    <p class="mb-0 text-xs">Hi! I need more information..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="marie"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Anne Marie</h6>
                    <p class="mb-0 text-xs">Awesome work, can you..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="ivana"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Ivanna</h6>
                    <p class="mb-0 text-xs">About files I can..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="peterson"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Peterson</h6>
                    <p class="mb-0 text-xs">Have a great afternoon..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="nick"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Nick Daniel</h6>
                    <p class="mb-0 text-xs">Hi! I need more information..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12">
          <div class="mb-4 card">
            <div v-if="false" class="p-3 pb-0 card-header">
              <h6 class="mb-1">Projects</h6>
              <p class="text-sm">Architects design houses</p>
            </div>
            <div class="p-3 card-body">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                      <tr>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Title
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Post
                        </th>
                        <th
                          class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Date Posted
                        </th>
                        <th class="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody v-for="item of archivedPosts" :key="item.id">
                        <tr
                         style="cursor: pointer;"
                         @click="goTo(item.pk,item)"
                        >
                        <td>
                          <div class="d-flex px-2 py-1">
                            <!-- <div>
                              <soft-avatar
                                :img="img1"
                                size="sm"
                                border-radius="lg"
                                class="me-3"
                                alt="user1"
                              />
                            </div> -->
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="mb-0 text-sm">{{ item.title  }}</h6>
                              <p class="text-xs text-secondary mb-0">
                                {{ item.description }}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <soft-badge  color="warning" variant="gradient" size="sm"
                            @click="goTo(item.pk,item)"
                            >READ</soft-badge
                          >
                          <!-- <soft-badge v-if="!getPostLogStatus(item.id)" color="danger" variant="gradient" size="sm"
                            >UNREAD</soft-badge
                          > -->
                        </td>
                        <td  class="align-middle text-center">
                          <span class="text-secondary text-xs font-weight-bold">
                            {{ item.type }}
                          </span>
                        </td>
                        <td class="align-middle text-center">
                          <span class="text-secondary text-xs font-weight-bold"
                            >{{ formatDate(item.date_created) }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                </table>
              
              <!-- <div class="row">
                <default-project-card
                  v-for="item in posts" :key="item.id"
                  :title="item.title.substring(0,20)"
                  :image="perfomance"
                  label="Marketing and Public Relations"
                  :description="item.description.substring(0,60)"
                  :post_id="item.id"
                  :authors="[
                    {
                      image: team4,
                      name: 'Peterson',
                    },
                    {
                      image: team3,
                      name: 'Nick Daniel',
                    },
                    {
                      image: team1,
                      name: 'Elena Morison',
                    },
                    {
                      image: team2,
                      name: 'Ryan Milly',
                    },
                  ]"
                  :action="{
                    color: 'primary',
                    label: 'View Post',
                  }"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import ProfileInfoCard from "./components/ProfileInfoCard.vue";
  import SoftAvatar from "@/components/SoftAvatar.vue";
  import sophie from "@/assets/img/kal-visuals-square.jpg";
  import marie from "@/assets/img/marie.jpg";
  import ivana from "@/assets/img/ivana-square.jpg";
  import ArchivesIcon from "../components/Icon/ArchivesIcon.vue";
  import peterson from "@/assets/img/team-4.jpg";
  import nick from "@/assets/img/team-3.jpg";
  import img1 from "@/assets/img/home-decor-1.jpg";
  import img2 from "@/assets/img/home-decor-2.jpg";
  import img3 from "@/assets/img/home-decor-3.jpg";
  import perfomance from "@/assets/img/bonus.jpeg";
  import coins from "@/assets/img/coins.jpeg";
  import prices from "@/assets/img/prices.png";
  import job from "@/assets/img/job.png";
  import team1 from "@/assets/img/team-1.jpg";
  import team2 from "@/assets/img/team-2.jpg";
  import team3 from "@/assets/img/team-3.jpg";
  import team4 from "@/assets/img/team-4.jpg";
  import {
    faFacebook,
    faTwitter,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";
  // import DefaultProjectCard from "./components/DefaultProjectCard.vue";
  // import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";

import SoftBadge from "@/components/SoftBadge.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapMutations,mapActions } from "vuex";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton  from "@/components/SoftButton.vue";
import {formatDate} from "../utils/index"
import axios from "axios"
import moment  from "moment";
  export default {
    name: "Archives",
    components: {
      SoftSwitch,
      ProfileInfoCard,
      SoftAvatar,
      // DefaultProjectCard,
      SoftBadge,
      SoftInput,
      SoftButton,
      ArchivesIcon
    },
    data() {
      return {
        showMenu: false,
        sophie,
        marie,
        ivana,
        peterson,
        nick,
        img1,
        team1,
        team2,
        team3,
        team4,
        img2,
        img3,
        perfomance,
        prices,
        job,
        coins,
        faFacebook,
        faTwitter,
        faInstagram,
        archivedPosts:[],
        startDate:"",
        endDate:"",
        category_id:0,

      };
    },
    methods:{
      ...mapGetters(["currentPosts","getPostLogs","currentUser","getPostCategories"]),
      ...mapMutations(["fetchPostLogs","fetchPosts","fetchCategories",]),
      ...mapActions(["setNewPost","saveNewPostLog","fetchCategoriesAction"]),
      formatDate,
      getPostLogStatus(post_id){
        const currentUser = this.currentUser();
        const {user_id} = currentUser
        const postLogs = this.getPostLogs()
        const existingLog = postLogs.find(pLog=>pLog.post_id.pk===post_id && pLog.user_id.pk===user_id)
        if(existingLog){
          return true
        }
        return false
      },
      getCategoryOptions(){
      return [ {'id': 0,'description': "N/A",'name': 'ALL'},...this.getPostCategories()]
    },
      filter(){
        if(this.endDate.length > 0 && this.startDate.length > 0){
          this.fetchArchivedPosts()
        }
      },
      handleSelect(event){
        this.category_id = event.target.value
      },
    // get_archived_given range
      async fetchArchivedPosts(){
        try{
          const accessToken = this.currentUser().access
          const response = await axios.get(
            `intranet/posts/get_archives_posts/?start_date=${moment(this.startDate).format('MM-DD-YYYY')}&end_date=${moment(this.endDate).format('MM-DD-YYYY')}`,
            {
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
            }
          )
          if(response.status == 200){
            const archivedPosts = response.data
            if(+this.category_id!==0 ){
              this.archivedPosts = archivedPosts.filter(post=>post.category_id.id===+this.category_id)
              return
            }
            this.archivedPosts = archivedPosts
          }
        }catch(error){
          console.log(error)
        }
      },
      
      async goTo(post_id,obj) {
        const postLogs = this.getPostLogs();
        const currentUser = this.currentUser();
        const {user_id}=currentUser
        const existingLog = postLogs.find(pLog=>pLog.post_id.pk===post_id && pLog.user_id.pk===user_id)
        if(!existingLog){
          this.saveNewPostLog({
          data:{
          post_id:post_id,user_id
          },
          access:currentUser.access
        })
        } 
        this.setNewPost({id:obj.pk})
        this.$router.push(`/read`,{
        })
    }
    },
    created(){
      this.fetchCategoriesAction({access:this.currentUser().access})
      const posts = this.currentPosts()
      this.posts=posts
    },
    mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
  };
  </script>
  
