<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Recent Posts</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                style="text-align: left;"
              >
                Title
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                style="text-align: left;"
              >
                Status
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              style="text-align: left;">
                Post
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                style="text-align: left;"
              >
                Date Posted
              </th>
            </tr>
          </thead>
          <tbody v-for="item of this.getPaginatedData()" :key="item.pk">
              <tr
              class="pe-auto"
              role="button"
              @click="readArticle(item)">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.title  }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm" style="text-align: left;">
                <soft-badge v-if="getPostLogStatus(item.pk)" color="success" variant="gradient" size="sm"
                  >READ</soft-badge
                >
                <soft-badge v-if="!getPostLogStatus(item.pk)" color="danger" variant="gradient" size="sm"
                  >UNREAD</soft-badge
                >
              </td>
              <td  class="align-middle" style="text-align: left;">
                <span class="text-secondary text-xs font-weight-bold">
                  {{ capitalize(item.type) }}
                </span>
              </td>
              <td class="align-middle" style="text-align: left;">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ formatDate(item.date_created) }}</span
                >
              </td>
            </tr>
            <!-- <tr>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <soft-avatar
                      :img="img2"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">FBC Bank Treasury</h6>
                    <p class="text-xs text-secondary mb-0">
                      Gold Coin Price
                    </p>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                >UNREAD</soft-badge
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                >23/04/23</span
                >
              </td>
              <td class="align-middle">
                <i class="fas fa-eye text-primary text-lg me-1" aria-hidden="true"></i>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <soft-avatar
                      :img="img3"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">Performance Bonus Circular</h6>
                    <p class="text-xs text-secondary mb-0">
                      Internal Job Advert - Operations Controller
                    </p>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                >UNREAD</soft-badge
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                >23/04/23</span
                >
              </td>
              <td class="align-middle">
                <i class="fas fa-eye text-primary text-lg me-1" aria-hidden="true"></i>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <soft-avatar
                      :img="img4"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">FBC SECURITIES RESEARCH</h6>
                    <p class="text-xs text-secondary mb-0">
                      Today's Prices
                    </p>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                >UNREAD</soft-badge
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                >23/04/23</span
                >
              </td>
              <td class="align-middle">
                <i class="fas fa-eye text-primary text-lg me-1" aria-hidden="true"></i>
              </td>
            </tr> -->
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-3">
                <soft-pagination color="success">
                  <soft-pagination-item prev @click="backPage"/>       
                  <soft-pagination-item v-for="item in Math.ceil(this.currentPosts().length / perPage)" 
                  :active="page===item"
                  :key="item" :label="`${item}`"   @click="goToPage(item)"/>
                  <soft-pagination-item next @click="nextPage" />
                </soft-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import { mapGetters,mapActions } from "vuex";
import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import {formatDate,capitalize} from "../../utils/index"
// import handlePagination from "../../utils/handlePagination";
export default {
  name: "authors-table",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      posts:[],
      perPage:5,
      page:1
    };
  },
  created(){
    this.posts = this.currentPosts();
    // const handlePaginationValue = handlePagination(this.currentPosts());
    // console.log({handlePaginationValue})
    // this.$.data = {...this.$.data,...handlePaginationValue};
  },
  // setup(){
  //   const handlePaginationValue = handlePagination(this.currentPosts());
  //   return { ...handlePaginationValue };
  // },
  methods:{
      formatDate,
      capitalize,
      ...mapGetters([
          "currentPosts",
          "getPostLogs",
          "currentUser"
        ]),
    ...mapActions(["setNewPost","saveNewPostLog"]),
       nextPage(){
        if(this.page!==Math.ceil(this.posts.length/this.perPage)){
          this.page +=1 
        }
      },
      backPage(){
        if(this.page !==1){
          this.page -=1
        }
      },
      goToPage(numPage){
        this.page = numPage
      },
      getPaginatedData(){
        return this.currentPosts().slice((this.page-1) * this.perPage,this.page * this.perPage)
      },
      async readArticle(item) {
          const postLogs = this.getPostLogs();
          const currentUser = this.currentUser();
          const {user_id}=currentUser
          const existingLog = postLogs.find(pLog=>pLog.post_id===item.pk && pLog.user_id===user_id)
          if(!existingLog){
            this.saveNewPostLog({
                data:{
                post_id:item.pk,user_id
                },
                access:this.currentUser().access
        })
          } 
          let post_id = item.pk
          this.setNewPost({id:post_id})
          this.$router.push(`/read`,{
          })
    },
      getPostLogStatus(post_id){
        const currentUser = this.currentUser();
        const {user_id} = currentUser
        const postLogs = this.getPostLogs()
        const existingLog = postLogs.find(pLog=>pLog.post_id.pk===post_id && pLog.user_id.pk===user_id)
        if(existingLog){
          return true
        }
        return false
      },
    },
  components: {
    // SoftAvatar,
    SoftBadge,
    SoftPagination, SoftPaginationItem
  },
};
</script>

<style>
tr{
  width: 100%;
}
tbody tr:hover {
   /* change the background color when hovering
  text-decoration: underline; */
  background: #f1f1f1;
}

</style>
