<template>
  <div class="container-fluid">
    <div v-if="modalOpen" class="modal fade show" 
    tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" 

    style="display:block;">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h5 class="modal-title">
              Executive Profile
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="mb-4 col-xl-3 col-md-4  mb-xl-0 mt-4 pe-auto" >
                <div class="card card-blog card-plain">
                  <div class="position-relative">
                    <a 
                    >
                      <img
                        :src="getImage(selectedExec.photo)"
                        alt="img-blur-shadow"
                        height="200"
                        class="shadow img-fluid2 border-radius-xl"
                      />
                    
                    </a>
                  </div>
                </div>
                <div class="px-2 pb-0 pt-2 ">
                  <h5>
                    {{ selectedExec.full_name }}
                  </h5>
                </div>
              </div>
              <div class="col">
                <h5>{{  selectedExec.title }}</h5>
                <h6>{{ selectedExec.qualifications_summary }}</h6>
                <p>
                  {{ selectedExec.profile }}
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button  @click="openCloseFun()" class="btn btn-primary" >Close</button>
        </div>
        </div>
        
    </div>
    </div>
    <div class="mt-3 page-header min-height-200 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '50%',
    }">
      <span class="mask bg-gradient-primary opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <holdings-info-icon></holdings-info-icon>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Holdings and Group Info</h5>
            <p v-if="false" class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div v-if="false" class="nav-wrapper position-relative end-0">
            <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
              <li class="nav-item">
                <a class="px-0 py-1 mb-0 nav-link active" data-bs-toggle="tab" href="javascript:;" role="tab"
                  aria-selected="true">
                  <span class="ms-1">Vision and Mission</span>
                </a>
              </li>
              <!-- <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="ms-1">Mission</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="ms-1">Principles</span>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div v-if="false" class="mt-3 row">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Platform Settings</h6>
          </div>
          <div class="p-3 card-body">
            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              Account
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault" name="email" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Email me when someone follows
                  me</soft-switch>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault1" name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto">Email me when someone answers
                  on my post</soft-switch>
              </li>

              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault2" name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto" checked>Email me when someone
                  mentions me</soft-switch>
              </li>
            </ul>
            <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
              Application
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault3" name="Project Launch" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80">New launches and projects</soft-switch>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault4" name="Product Update" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Monthly product updates</soft-switch>
              </li>
              <li class="px-0 pb-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault5" name="Newsletter" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80">Subscribe to newsletter</soft-switch>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <profile-info-card title="Profile Information"
          description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA',
          }" :social="[
  {
    link: 'https://www.facebook.com/CreativeTim/',
    icon: faFacebook,
  },
  {
    link: 'https://twitter.com/creativetim',
    icon: faTwitter,
  },
  {
    link: 'https://www.instagram.com/creativetimofficial/',
    icon: faInstagram,
  },
]" :action="{
  route: 'javascript:;',
  tooltip: 'Edit Profile',
}" />
      </div>
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Conversations</h6>
          </div>
          <div class="p-3 card-body">
            <ul class="list-group">
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="sophie" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Sophie B.</h6>
                  <p class="mb-0 text-xs">Hi! I need more information..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="marie" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Anne Marie</h6>
                  <p class="mb-0 text-xs">Awesome work, can you..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="ivana" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Ivanna</h6>
                  <p class="mb-0 text-xs">About files I can..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="peterson" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Peterson</h6>
                  <p class="mb-0 text-xs">Have a great afternoon..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
              <li class="px-0 border-0 list-group-item d-flex align-items-center">
                <soft-avatar class="me-3" :img="nick" alt="kal" border-radius="lg" shadow="regular" />
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Nick Daniel</h6>
                  <p class="mb-0 text-xs">Hi! I need more information..</p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">Reply</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="display-6">FBC Holdings</p>
      FBC Holdings Limited is the investment holding company of subsidiaries that provide commercial and investment
      banking, mortgage finance, microfinance, stockbroking, short term insurance and reinsurance services. It is listed
      on the Zimbabwe Stock Exchange.
    </div>
    <div>
      <h5>Mission</h5>
      <p>{{ holdingsInfo.mission }}</p>
    </div>
    <div>
      <h5>Vision</h5>
      <p>{{ holdingsInfo.vision }}</p>
    </div>
    <div class="mt-3 row ">
      <div class="col">
        <h5>Principles</h5>
        <ul>
          <li v-for="principle in holdingsInfo.principles" :key="principle">
            {{ principle }}
          </li>
        </ul>
      </div>
      <div class="col">
        <h5>Values</h5>
        <ul>
          <li v-for="value in holdingsInfo.values" :key="value">
            {{ value }}
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-3 row h-100">
      <h5>Promise</h5>
      <p>{{ holdingsInfo.promise }}</p>
    </div>
    <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">FBC Holdings Executive management</p>
            <chevron-down @click="showOption('fbc_holdings')" v-if="!menuOptions.fbc_holdings" />
            <chevron-up @click="showOption('fbc_holdings')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.fbc_holdings" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.fbc_holdings)" 
            :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
    </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">FBC Bank</p>
      <div>
        FBC Bank Limited is a commercial bank registered in accordance with the Zimbabwe Banking Act (Chapter 24:20),
        serves individual clients, small to middle market businesses, large corporates and institutions with a full
        complement of commercial and investment banking services.
      </div>
      <!-- Put the executive management -->
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">FBC Bank Executive management</p>
            <chevron-down @click="showOption('fbc_bank')" v-if="!menuOptions.fbc_bank" />
            <chevron-up @click="showOption('fbc_bank')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.fbc_bank" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.fbc_bank)" 
            :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
    </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">FBC Building Society</p>
      <div>
        FBC Building Society constructs residential properties, as well as providing mortgage lending, savings deposit
        accounts and other money market investment products to the public.
      </div>
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">FBC Building Society Executive management</p>
            <chevron-down @click="showOption('fbc_bank_bs')" v-if="!menuOptions.fbc_bank_bs" />
            <chevron-up @click="showOption('fbc_bank_bs')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.fbc_bank_bs" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.fbc_bank_bs)" :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
      </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">Microplan</p>
      <div>
        Services (Private) Limited MicroPlan Financial Services (Private) Limited is a microfinance institution that
        focuses on micro lending to the unbanked sector and hence caters for the lower income segment clients.
      </div>
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">Microplan Executive management</p>
            <chevron-down @click="showOption('microplan')" v-if="!menuOptions.microplan" />
            <chevron-up @click="showOption('microplan')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.microplan" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.microplan)" :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
    </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">FBC Securities</p>
      <div>
        FBC Securities (Private) Limited is a registered stock broking company whose core business is to facilitate
        equities trading at the Zimbabwe Stock Exchange on behalf of clients. FBC Securities (Private) Limited offers a
        range of services to its clients that include research, advisory services, private portfolio management, and
        sponsoring broker services
      </div>
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">FBC Securites Executive management</p>
            <chevron-down @click="showOption('fbc_securities')" v-if="!menuOptions.fbc_securities" />
            <chevron-up @click="showOption('fbc_securities')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.fbc_securities" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.fbc_securities)" :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
      </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">FBC Insurance</p>
      <div>
        FBC Insurance (Private) Limited is a short-term insurance company licenced to offer a wide array of insurance
        products that include motor, engineering, accident, fire, marine, farming, and aviation cover.
      </div>
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">FBC Insurance Executive management</p>
            <chevron-down @click="showOption('fbc_insurance')" v-if="!menuOptions.fbc_insurance" />
            <chevron-up @click="showOption('fbc_insurance')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.fbc_insurance" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.fbc_insurance)" :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
      </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">FBC Reinsurance</p>
      <div>
        FBC Reinsurance Limited provides risk transfer solutions to insurers in Africa and beyond. Its major role is to
        provide underwriting capacity to allow short term insurance companies to assume greater individual risks than
        their size would otherwise allow, protect the insurer’s (i.e. cedant’s) balance sheet against catastrophic losses
        and larger than predicted accumulation of claims, management advice and risk management.
      </div>
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">FBC Reinsurance Executive management</p>
            <chevron-down @click="showOption('fbc_reinsurance')" v-if="!menuOptions.fbc_reinsurance" />
            <chevron-up @click="showOption('fbc_reinsurance')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.fbc_reinsurance" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.fbc_reinsurance)" :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>
      </div>
      </div>
    </div>
    <div class="mt-3 ">
      <p class="display-6">Xarani</p>
      <div>
        Xarani is the market leader in real-time digital business solutions and API’s.
        <ul>
          <li>Digital Onboarding</li>
          <li>Electronic Know-Your-Customer</li>
          <li>Real-Time Proof-Of-Life</li>
          <li>Digital Identity</li>
          <li>Credit Scoring</li>
        </ul>
      </div>
      <div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p style="display: inline;" class="h6">Xarani Executive management</p>
            <chevron-down @click="showOption('xarani')" v-if="!menuOptions.xarani" />
            <chevron-up @click="showOption('xarani')" v-else />
          </div>
        </div>
        <div v-if="menuOptions.xarani" class="row">
          <executive-card v-for="item in getExecutiveByUnit(sbu_units.xarani)" 
            :key="item.id"
            :title="item.full_name" 
            :image="getImage(item.photo)" 
            :label="item.full_name" 
            :description="item.title"
            :item="item"
            @view-profile="obj=>handleViewProfile(obj)"
            :action="{
              color: 'primary',
              label: 'View Profile',
            }" />
        </div>

      </div>
    </div>
    <div v-if="false" class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div v-if="false" class="p-3 pb-0 card-header">
            <h6 class="mb-1">Projects</h6>
            <p class="text-sm">Architects design houses</p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <default-project-card 
              title="Performance Bonus Circular" :image="perfomance"
                label="Marketing and Public Relations" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Internal Job Advert - Clerk" :image="job"
                label="Marketing and Public Relations" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Today's Prices" :image="prices" label="FBC Securities Research" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Gold Coin Price" :image="coins" label="FBC Bank Treasury" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Internal Job Advert - Clerk" :image="job"
                label="Marketing and Public Relations" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Gold Coin Price" :image="coins" label="FBC Bank Treasury" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Performance Bonus Circular" :image="perfomance"
                label="Marketing and Public Relations" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />

              <default-project-card title="Today's Prices" :image="prices" label="FBC Securities Research" description="Different people have different taste, and various types
                      of music." :authors="[
                        {
                          image: team4,
                          name: 'Peterson',
                        },
                        {
                          image: team3,
                          name: 'Nick Daniel',
                        },
                        {
                          image: team1,
                          name: 'Elena Morison',
                        },
                        {
                          image: team2,
                          name: 'Ryan Milly',
                        },
                      ]" :action="{
  color: 'primary',
  label: 'View Post',
}" />


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import perfomance from "@/assets/img/bonus.jpeg";
import coins from "@/assets/img/coins.jpeg";
import prices from "@/assets/img/prices.png";
import job from "@/assets/img/job.png";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import ExecutiveCard from "./components/ExecutiveCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { mapActions, mapGetters } from "vuex";

import ChevronDown from "../components/Icon/ChevronDown.vue";
import ChevronUp from "../components/Icon/ChevronUp.vue";
import axios from "axios";
import HoldingsInfoIcon from "../components/Icon/HoldingsInfoIcon.vue";
export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    SoftAvatar,
    ExecutiveCard,
    HoldingsInfoIcon,
    ChevronDown,
    ChevronUp
  },
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      perfomance,
      prices,
      job,
      coins,
      faFacebook,
      faTwitter,
      faInstagram,
      holdingsInfo: {},
      executives: [],
      sbu_units: {
        fbc_holdings: 'FBC Holdings',
        fbc_bank: 'FBC Bank',
        fbc_bank_bs: 'FBC Building Society',
        microplan: 'Microplan',
        fbc_securities: 'FBC Securities',
        fbc_insurance: 'FBC Insurance',
        fbc_reinsurance: 'FBC Reinsurance',
        xarani: 'Xarani'
      },
      selectedExec:{},
      menuOptions:{
        fbc_holdings: false,
        fbc_bank: false,
        fbc_bank_bs: false,
        microplan: false,
        fbc_securities: false,
        fbc_insurance: false,
        fbc_reinsurance: false,
        xarani: false,
      },
      modalOpen:false
      
    };
  },
  methods: {
    ...mapGetters(["getHoldingsInfo", "getBaseUrl","currentUser"]),
    ...mapActions(["fetchHoldingsInfoAction"]),
    async getExecutives() {
      try {
        const accessToken = this.currentUser().access
        const response = await axios.get("intranet/posts/fetch_executives/",
        {
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
        }
        );
        if (response.status === 200) {
          const data = response.data
          this.executives = data
        }
      } catch (e) {
        console.log({ e })
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleViewProfile(_obj){
      // this.selectedExec = obj
      // this.openCloseFun()
    },
    getExecutiveByUnit(sbu_unit) {
      return this.executives.filter(exeObj => exeObj.sbu_unit === sbu_unit)
    },
    getImage(imageUrl) {
      if (!imageUrl) {
        return job
      }
      const str = this.getBaseUrl()
      if (str.charAt(str.length - 1) === "/") {
        return str.slice(0, -1) + imageUrl
      }
      return this.getBaseUrl() + imageUrl
    },
    showOption(option) {
      const after = {
        ...this.menuOptions,[option]:!this.menuOptions[option]
      };
      this.menuOptions = after
      // change by the exec management option
    },
    openCloseFun(){
      this.modalOpen = !this.modalOpen;
    },
    async fetchHoldingsInfo() {
      try {
        const accessToken = this.currentUser().access
        const response = await axios.get(
          "intranet/posts/fetchHoldings_details/",{
            headers:{
              Authorization:`Bearer ${accessToken}`
            }
          }
        )
        if (response.status == 200) {
          const unformattedData = response.data.length > 0 ? response.data[0] : {}
          if (Object.keys(unformattedData).length > 0) {
            const newObject = {
              ...unformattedData,
              values: unformattedData["values"].split(',').map(str => str.replace('\n', '')),
              principles: unformattedData["principles"].split(',').map(str => str.replace('\n', ''))
            }
            this.holdingsInfo = newObject
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);

  },
  created() {
    // this.fetchHoldingsInfoAction({access:this.currentUser().access})
    this.fetchHoldingsInfo()
    // this.holdingsInfo = this.getHoldingsInfo()
    this.getExecutives();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>

<style>
.icon_size {
  width: 30px;
  height: 30px;
}
</style>
