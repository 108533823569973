<template>
<svg fill="#000000" height="80px" width="80px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 392.663 392.663" xml:space="preserve">
<g fill="#18408B">
	<g fill="#18408B">
		<path d="M349.931,286.449v-41.697c0-6.012-4.848-10.925-10.925-10.925H207.321v-91.346h49.39c6.012,0,10.925-4.848,10.925-10.925
			V10.925C267.636,4.913,262.788,0,256.711,0H136.016c-6.012,0-10.925,4.848-10.925,10.925v120.63
			c0,6.012,4.849,10.925,10.925,10.925h49.39v91.281H53.721c-6.012,0-10.925,4.848-10.925,10.925v41.697
			c-24.372,5.107-42.731,26.764-42.731,52.622c0,29.543,24.113,53.657,53.657,53.657s53.657-24.113,53.657-53.657
			c0-25.859-18.36-47.515-42.731-52.558v-30.836h120.76v30.836c-24.436,5.042-42.731,26.634-42.731,52.558
			c0,29.608,24.113,53.657,53.657,53.657c29.608,0,53.657-24.113,53.657-53.657c0-25.859-18.36-47.515-42.731-52.558v-30.836h120.76
			v30.836c-24.372,5.042-42.731,26.634-42.731,52.558c0,29.608,24.113,53.657,53.657,53.657c29.608,0,53.657-24.113,53.657-53.657
			C392.663,313.083,374.303,291.426,349.931,286.449z M85.656,339.006c0,17.519-14.287,31.806-31.806,31.806
			c-17.519,0-31.806-14.287-31.806-31.806c0-17.519,14.287-31.806,31.806-31.806C71.37,307.071,85.656,321.358,85.656,339.006z
			 M228.202,339.006c0,17.519-14.287,31.806-31.806,31.806c-17.519,0-31.806-14.287-31.806-31.806
			c0-17.519,14.287-31.806,31.806-31.806C213.915,307.071,228.202,321.358,228.202,339.006z M147.006,120.695V21.85h98.844v98.844
			H147.006z M339.071,370.812c-17.519,0-31.806-14.287-31.806-31.806s14.287-31.806,31.806-31.806s31.806,14.287,31.806,31.806
			S356.59,370.812,339.071,370.812z"/>
	</g>
</g>
</svg>
</template>